/* eslint-disable react-hooks/exhaustive-deps */
import { FC, createContext, useContext, useState, useEffect } from 'react'
import { DefaultLayoutConfig } from './DefaultLayoutConfig'
import {
  getEmptyCssClasses,
  getEmptyCSSVariables,
  getEmptyHTMLAttributes,
  getEmptySettings,
  LayoutSetup,
} from './LayoutSetup'
import {
  ILayout,
  ILayoutCSSVariables,
  ILayoutsettings,
  ILayoutCSSClasses,
  ILayoutHTMLAttributes,
} from './LayoutModels'
import { WithChildren } from '../../helpers'
import { setting } from './../../../app/services/settings'

export interface LayoutContextModel {
  config: ILayout
  classes: ILayoutCSSClasses
  attributes: ILayoutHTMLAttributes
  cssVariables: ILayoutCSSVariables
  settings: ILayoutsettings
  setLayout: (config: LayoutSetup) => void
}

const LayoutContext = createContext<LayoutContextModel>({
  config: DefaultLayoutConfig,
  classes: getEmptyCssClasses(),
  attributes: getEmptyHTMLAttributes(),
  cssVariables: getEmptyCSSVariables(),
  settings: getEmptySettings(),
  setLayout: (config: LayoutSetup) => { },
})

const enableSplashScreen = () => {
  const splashScreen = document.getElementById('splash-screen')
  if (splashScreen) {
    splashScreen.style.setProperty('display', 'flex')
  }
}

const disableSplashScreen = () => {
  const splashScreen = document.getElementById('splash-screen')
  if (splashScreen) {
    splashScreen.style.setProperty('display', 'none')
  }
}

const LayoutProvider: FC<WithChildren> = ({ children }) => {
  const [config, setConfig] = useState(LayoutSetup.config)
  const [classes, setClasses] = useState(LayoutSetup.classes)
  const [attributes, setAttributes] = useState(LayoutSetup.attributes)
  const [cssVariables, setCSSVariables] = useState(LayoutSetup.cssVariables)
  const [settings, setSetting] = useState(LayoutSetup.settings);

  const setLayout = (_themeConfig: Partial<ILayout>) => {
    enableSplashScreen()
    const bodyClasses = Array.from(document.body.classList)
    bodyClasses.forEach((cl) => document.body.classList.remove(cl))
    LayoutSetup.updatePartialConfig(_themeConfig)
    setConfig(Object.assign({}, LayoutSetup.config))
    setClasses(LayoutSetup.classes)
    setAttributes(LayoutSetup.attributes)
    setCSSVariables(LayoutSetup.cssVariables)

  }

  const favicon = document.querySelector("link[rel~='icon']");
  const title = document.querySelector("title");
  const splash_screen_image = document.getElementById("splash-screen-image");

  const value: LayoutContextModel = {
    config,
    classes,
    attributes,
    cssVariables,
    settings,
    setLayout,
  }
  useEffect(() => {

    setting().then((response) => {

      setSetting(response.data);
      if (typeof response.data != 'undefined') {
        if (typeof response.data.data != 'undefined') {
          if (typeof response.data.data.attributes != 'undefined') {
            if (favicon != null) {
              if (typeof response.data.data.attributes.favicon != 'undefined') {
                if (typeof response.data.data.attributes.favicon.data != 'undefined') {
                  if (typeof response.data.data.attributes.favicon.data.attributes != 'undefined') {
                    if (typeof response.data.data.attributes.favicon.data.attributes.url != 'undefined') {
                      (favicon as HTMLAnchorElement).href = `${process.env.REACT_APP_PREVIEW_REACT_URL}/public${response.data.data.attributes.favicon.data.attributes.url}`;
                      (splash_screen_image as HTMLImageElement).src = `${process.env.REACT_APP_PREVIEW_REACT_URL}/public${response.data.data.attributes.favicon.data.attributes.url}`;
                    }
                  }
                }
              }
            }
            if (title != null) {
              if (typeof response.data.data.attributes.title != 'undefined') {
                title.text = response.data.data.attributes.title
                title.textContent = response.data.data.attributes.title
              }
            }
          }
        }
      }
      disableSplashScreen()
    })
  }, [])

  return <LayoutContext.Provider value={value}>{children}</LayoutContext.Provider>
}

export { LayoutContext, LayoutProvider }

export function useLayout() {
  return useContext(LayoutContext)
}
