import { FC } from 'react'
import { Link } from 'react-router-dom'
import { Trip } from '../../core/_models'

type Props = {
    trip: Trip
}

const UserCell: FC<Props> = ({ trip }) => (
    <div className='d-flex align-items-center'>
        <div className='d-flex flex-column'>
            <Link className='text-gray-800 text-hover-primary mb-1'
                to={`${trip.creator.data !== null ?
                    trip.creator.data.attributes.role.data.id === 4 ?
                        '/app/agents/' + trip.creator.data.id
                        : trip.creator.data.attributes.role.data.id === 5 ?
                            '/app/customers/' + trip.creator.data.id
                            : '#'
                    : '#'}`}
            >
                {`${trip.creator.data !== null ?
                    trip.creator.data.attributes.firstname + " " + trip.creator.data.attributes.lastname
                    : ''}`}
            </Link>
            <span>{`${trip.creator.data !== null ?
                trip.creator.data.attributes.role.data.attributes.name
                : ''}`}</span>
        </div>
    </div>
)

export { UserCell }
