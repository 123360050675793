import { FC } from 'react'
import { Customer } from '../../core/_models'

type Props = {
    customer: Customer
}

const PhoneCell: FC<Props> = ({ customer }) => (
    <div className='d-flex align-items-center'>
        <div className='d-flex flex-column'>
            <span>{customer.phone === null ? '-' : customer.phone}</span>
        </div>
    </div>
)

export { PhoneCell }
