import axios from 'axios'
import { AuthModel, UserModel } from './_models'

const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/users/me?populate=*`
export const PERMISSION = `${API_URL}/users-permissions/roles/`
export const LOGIN_URL = `${API_URL}/auth/local?populate=*`
export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/auth/forgot-password`
export const REQUEST_RESET_PASSWORD_URL = `${API_URL}/auth/reset-password`
export const CRON = `${API_URL}/adminsetting/cron`

// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post<AuthModel>(LOGIN_URL, {
    identifier: email,
    password,
  })
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<any>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export function getUserByToken(token: string) {
  return axios.get<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
    headers: { "Authorization": `Bearer ${token}` }
  })
}


export function getAdminsettingCron(token: string) {
  return axios.get<UserModel>(CRON, {
    headers: { "Authorization": `Bearer ${token}` }
  })
}

// reset Password
export function requestresetPassword(code: string, newPassword: string, passwordConfirmation: string) {
  return axios.post<{ result: boolean }>(REQUEST_RESET_PASSWORD_URL, {
    code: code,
    password: newPassword,
    passwordConfirmation: passwordConfirmation,
  })
}

export function getPermission(id: number) {
  const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v'
  const lsValue = JSON.parse(localStorage.getItem(AUTH_LOCAL_STORAGE_KEY) || '{}')

  return axios.get<any>(PERMISSION+id, {
    headers: { "Authorization": `Bearer ${lsValue.jwt}` }
  })
}