import { Suspense, useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import { I18nProvider } from '../_rashmicab/i18n/i18nProvider'
import { LayoutProvider, LayoutSplashScreen } from '../_rashmicab/layout/core'
import { MasterInit } from '../_rashmicab/layout/MasterInit'
import { AuthInit } from './modules/auth'
import { ToastContainer } from 'react-toastify'
import { useAuth } from './modules/auth';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import 'react-toastify/dist/ReactToastify.css';
import Zoom from "yet-another-react-lightbox/plugins/zoom";
const App = () => {
    const { lightbox } = useAuth()
    const [lightboxController, setLightboxController] = useState({
        toggler: false,
        slide: 1,
    });
    useEffect(() => {
        if (lightbox !== undefined) {
            setLightboxController({
                toggler: lightbox.toggler,
                slide: lightbox.index,
            });
        }

    }, [lightbox]);

    return (

        <Suspense fallback={<LayoutSplashScreen />}>
            <I18nProvider >
                <LayoutProvider >
                    <ToastContainer />
                    <MasterInit />
                    <>
                        {
                            lightbox !== undefined ?
                                <Lightbox
                                    open={lightboxController.toggler}
                                    close={() => setLightboxController({ toggler: false, slide: 1 })}
                                    slides={lightbox.sources}
                                    index={lightboxController.slide}
                                    plugins={[Zoom]}
                                />
                                : ""
                        }
                    </>
                    <AuthInit>
                        <Outlet />
                    </AuthInit>
                </LayoutProvider>
            </I18nProvider>
        </Suspense>
    )
}

export { App }
