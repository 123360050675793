import clsx from 'clsx'
import { FC } from 'react'
import { Link } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../../../../_rashmicab/helpers'
import { Driver } from '../../core/_models'

type Props = {
    driver: Driver,
    id: any
}

const InfoCell: FC<Props> = ({ driver, id }) => {
    var agentid = '';
    if (typeof driver.agent !== "undefined") {
        agentid = '?agentid=' + driver.agent.data.id;
    }
    return (
        <div className='d-flex align-items-center'>
            <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
                <Link className='' to={'/app/drivers/' + id + agentid} >
                    {driver.avtar !== null && driver.avtar.data !== null ? (
                        <div className='symbol-label'>
                            <img src={toAbsoluteUrl(`${process.env.REACT_APP_PREVIEW_REACT_URL}/public${driver.avtar?.data?.attributes?.url}`)} alt={driver.name} className='w-100' />
                        </div>
                    ) : (
                        <div
                            className={clsx(
                                'symbol-label fs-3',
                                `bg-light-undefined`,
                                `text-undefined`
                            )}
                        >
                            {driver.name ?
                                driver.name.charAt(0).toUpperCase() : ""
                            }
                        </div>
                    )}
                </Link>
            </div>
            <div className='d-flex flex-column'>
                <Link className='text-gray-800 text-hover-primary mb-1' to={'/app/drivers/' + id + agentid} >
                    {`${driver.name ? driver.name : ''}`}
                </Link>
                {
                    typeof driver.agent !== "undefined" ?
                        typeof driver.agent.data !== "undefined" ?
                            driver.agent.data !== null ?
                                < span > {driver.agent?.data?.attributes?.firstname + " " + driver.agent.data.attributes.lastnamesC}
                                </span>
                                : ""
                            : ""
                        : ""
                }
            </div>
        </div >
    )
}
export { InfoCell }
