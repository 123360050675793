import clsx from 'clsx'
import { FC } from 'react'
import { Link } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../../../../_rashmicab/helpers'
import { Agent } from '../../core/_models'

type Props = {
    agent: Agent
}

const InfoCell: FC<Props> = ({ agent }) => (
    <div className='d-flex align-items-center'>
        <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
            <Link className='' to={'/app/agents/' + agent.id} >
                {agent.image !== null ? (
                    <div className='symbol-label'>
                        <img src={toAbsoluteUrl(`${process.env.REACT_APP_PREVIEW_REACT_URL}/public${agent.image.url}`)} alt={agent.username} className='w-100' />
                    </div>
                ) : (
                    <div
                        className={clsx(
                            'symbol-label fs-3',
                            `bg-light-${agent.initials?.state}`,
                            `text-${agent.initials?.state}`
                        )}
                    >
                        {agent?.firstname.charAt(0).toUpperCase()}
                    </div>
                )}
            </Link>
        </div>
        <div className='d-flex flex-column'>
            <Link className='text-gray-800 text-hover-primary mb-1' to={'/app/agents/' + agent.id} >
                {`${agent.firstname ? agent.firstname : ''} ${agent.lastname ? agent.lastname : ""}`}
            </Link>
            <span>{agent.email}</span>
        </div>
    </div>
)

export { InfoCell }
