import axios, { AxiosResponse } from 'axios'
import { QueryResponse } from './_models'

const API_URL = process.env.REACT_APP_API_URL
// const GET_USERS_URL = `${API_URL}/users?fields[0]=id&fields[1]=firstname&fields[2]=lastname&fields[3]=email&fields[4]=w_plan`

const GET_wallet_URL = `${API_URL}/wallets?populate[agent][fields][0]=id&populate[agent][fields][1]=firstname&populate[agent][fields][2]=lastname&populate[agent][fields][3]=email&fields[4]=amount&fields[5]=plan_data&fields[6]=expiry_date&fields[7]=payment_id`;

const get = (query: string, token: string): Promise<QueryResponse> => {
    const table = query.split('&').map(pair => pair.split("="));
    const result = Object.fromEntries(table);
    const date = new Date().toISOString()
    var query_data = `&filters[$and][0][is_status][$eq]=true&filters[$and][0][expiry_date][$gte]=${date}`;
    query_data = `${query_data}&pagination[page]=1`;
    query_data = `${query_data}&pagination[pageSize]=50`;
    query_data = `${query_data}&sort=id:DESC`;

    return axios
        .get(`${GET_wallet_URL}${query_data}`, {
            headers: { "Authorization": `Bearer  ${token}` }
        })
        .then((d: AxiosResponse<QueryResponse>) => {
            if (result.pagesize === "all") {
                if (typeof d.data !== "undefined") {
                    if (typeof d.data.meta !== "undefined") {
                        if (typeof d.data.meta.pagination !== "undefined") {
                            if (typeof d.data.meta.pagination.pageSize !== "undefined") {
                                d.data.meta.pagination.pageSize = "all";
                            }
                        }
                    }
                }
            }
            return d.data;
        }
        )
}

// const get = (query: string, token: string): Promise<QueryResponse> => {
//     const table = query.split('&').map(pair => pair.split("="));
//     const result = Object.fromEntries(table);
//     const date = new Date().toISOString()
//     var query_data = `&filters[role]=4&filters[wallets][is_status][$eq]=true&filters[wallets][expiry_date][$gte]=${date}`;
//     query_data = `${query_data}&limit=19`;
//     query_data = `${query_data}&sort=updatedAt:DESC`;

//     return axios
//         .get(`${GET_USERS_URL}${query_data}`, {
//             headers: { "Authorization": `Bearer  ${token}` }
//         })
//         .then((d: AxiosResponse<QueryResponse>) => {
//             if (result.pagesize === "all") {
//                 if (typeof d.data !== "undefined") {
//                     if (typeof d.data.meta !== "undefined") {
//                         if (typeof d.data.meta.pagination !== "undefined") {
//                             if (typeof d.data.meta.pagination.pageSize !== "undefined") {
//                                 d.data.meta.pagination.pageSize = "all";
//                             }
//                         }
//                     }
//                 }
//             }
//             return d.data;
//         }
//         )
// }

export { get }
