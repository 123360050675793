import { FC } from 'react'

type Props = {
	subscription: any,
}

const AmountCell: FC<Props> = ({ subscription }) => (
	<div className='d-flex align-items-center'>
		<div className='d-flex flex-column'>
			{
				subscription !== null ?
					subscription.w_plan !== null ?
						<span>Rs. {subscription.amount === null ? '-' : subscription.amount}</span>
						: null
					: null
			}
		</div>
	</div>
)

export { AmountCell }
