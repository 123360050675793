import { FC } from 'react'

type Props = {
    message: any,
}

const MessagesCell: FC<Props> = ({ message }) => {
    return (
        <div className='d-flex align-items-center'>
            <div className='d-flex flex-column'>
                {
                    message.message !== null ?
                        <span dangerouslySetInnerHTML={{ __html: message.message }}></span>
                : null
                }
            </div>
        </div>
    )
}

export { MessagesCell }
