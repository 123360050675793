import { Link } from 'react-router-dom'
import { KTSVG } from '../../../../helpers'
import { useAuth } from '../../../../../app/modules/auth'
let notifications: Array<{ icon: string; color: string; title: string; description: string, to: string }> = []

const NotificationsTab = () => {
    const { permission } = useAuth()
    notifications = [];
    if (typeof permission !== "undefined") {

        if (permission['api::notification']['controllers']['notification']['find']['enabled']) {
            notifications.push({
                icon: '/media/icons/duotune/customicon/mail-notification.svg',
                color: 'primary',
                title: 'Notifications',
                description: 'Notifications for app',
                to: '/app/notifications',
            })
        }

    }
    return (
        <div className='mx-5'>
            {/*begin::Header*/}
            <h3 className='fw-bolder text-dark mb-10 mx-0'>Notifications</h3>
            {/*end::Header*/}

            {/*begin::Body*/}
            <div className='mb-12'>
                {notifications.map((t, jj) => (
                    <div className='d-flex align-items-center mb-7' key={t.title + jj + "tabmenu"}>
                        {/*begin::Symbol*/}
                        <div className='symbol symbol-50px me-5'>
                            <span className={`symbol-label bg-light-${t.color}`}>
                                <KTSVG path={t.icon} className={`svg-icon-2x svg-icon-${t.color}`} />
                            </span>
                        </div>
                        {/*end::Symbol*/}

                        {/*begin::Text*/}
                        <div className='d-flex flex-column'>
                            <Link
                                to={t.to}
                                className='text-gray-800 text-hover-primary fs-6 fw-bold'
                            >
                                {t.title}
                            </Link>

                            <span className='text-muted fw-bold'>{t.description}</span>
                        </div>
                        {/*end::Text*/}
                    </div>
                ))}
            </div>
            {/*end::Body*/}
        </div>
    )
}

export { NotificationsTab }
