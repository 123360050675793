import { Column } from 'react-table'
import { DateCell } from './DateCell'
import { UserCell } from './UserCell'
import { CityCell } from './CityCell'
import { ExpiryDateCell } from './ExpiryDateCell'
import { InfoCell } from './InfoCell'
import { CustomHeader } from './CustomHeader'
import { Onlinetrip } from '../../core/_models'

const Columns: ReadonlyArray<Column<Onlinetrip>> = [
    {
        Header: (props) => <CustomHeader tableProps={props} title='Route' className='min-w-125px' />,
        id: 'id',
        Cell: ({ ...props }) => <InfoCell trip={props.data[props.row.index].attributes} id={props.data[props.row.index].id} />,
    },
    {
        Header: (props) => <CustomHeader tableProps={props} title='Date' className='min-w-125px' />,
        id: 'datetime',
        Cell: ({ ...props }) => <DateCell trip={props.data[props.row.index].attributes} id={props.data[props.row.index].id} />,
    },
    {
        Header: (props) => <CustomHeader tableProps={props} title='Customer' className='min-w-125px' />,
        id: 'online_trip_customer_name',
        Cell: ({ ...props }) => <UserCell user={props.data[props.row.index].attributes} />,
    },
    {
        Header: (props) => <CustomHeader tableProps={props} title='City' className='min-w-125px' />,
        id: 'location',
        Cell: ({ ...props }) => <CityCell city={props.data[props.row.index].attributes.location} />,
    },
    {
        Header: (props) => <CustomHeader tableProps={props} title='Status' className='min-w-125px' />,
        id: 'status',
        Cell: ({ ...props }) => <ExpiryDateCell trip={props.data[props.row.index].attributes} />,
    },
]

export { Columns }
