/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import { initialQueryState } from '../../../../../../_rashmicab/helpers'
import { useQueryRequest } from '../../core/QueryRequestProvider'

const Tablist = () => {
    const { updateState } = useQueryRequest()
    const [tab, setTab] = useState('all')

    useEffect(() => {
        updateState({
            filter: { tab },
            ...initialQueryState,
        })
    }, [tab])

    return (
        <>
            {/* begin::Tablist */}
            <ul className="nav" role="tablist">
                <li className="nav-item" role="presentation">
                    <span className={tab === "all" ? "nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 me-1 active" : "nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 me-1"} onClick={(e) => { setTab('all') }}>All</span>
                </li>
                <li className="nav-item" role="presentation">
                    <span className={tab === "with_bid" ? "nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 me-1 active" : "nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 me-1"} onClick={(e) => { setTab('with_bid') }}>With Bid</span>
                </li>
                <li className="nav-item" role="presentation">
                    <span className={tab === "without_bid" ? "nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 me-1 active" : "nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 me-1"} onClick={(e) => { setTab('without_bid') }}>Without bid</span>
                </li>
                <li className="nav-item" role="presentation">
                    <span className={tab === "accepted" ? "nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 active" : "nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4"} onClick={(e) => { setTab('accepted') }}>Accepted</span>
                </li>
            </ul>
            {/* end::Tablist */}
        </>
    )
}

export { Tablist }
