/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { PageLink, PageTitle } from '../../../_rashmicab/layout/core'
import { TripList } from './triplist/List'
import { CabList } from './cablist/List'
import { DriverList } from './driverlist/List'
import { ECabList } from './expiredcablist/List'
import { EDriverList } from './expireddriverlist/List'
import { CustomerComplaintList } from './customercomplaintlist/List'
import { AgentComplaintList } from './agentcomplaintlist/List'
import { AgentList } from './agentlist/List'
import { CustomerList } from './customerslist/List'
import { InactiveAgentList } from './inactiveagentlist/List'
import { OnlinetripList } from './onlinetriplist/List'
import { SubscriptionList } from './subscriptionlist/List'
import { ExpiredAgentList } from './expiredagentlist/List'
import { UnsubscribedUserList } from './unsubscribeduserlist/List'
import { useAuth } from '../../../app/modules/auth'

const dashboardBreadCrumbs: Array<PageLink> = [
    {
        title: 'Home',
        path: '/dashboard',
        isSeparator: false,
        isActive: false,
    },
]

const DashboardPage = () => {
    const { permission } = useAuth()
    useEffect(() => {
        // We have to show toolbar only for dashboard page
        document.getElementById('kt_layout_toolbar')?.classList.remove('d-none')
        return () => {
            document.getElementById('kt_layout_toolbar')?.classList.add('d-none')
        }
    }, [])

    return (
        <>
            {
                typeof permission !== "undefined" ?
                    <>
                        {/* begin::Row */}
                        <div className='row gy-5 g-xl-8'>
                            {/* begin::Col */}
                            {
                                permission['api::trip']['controllers']['trip']['find']['enabled'] ?
                                    <div className='col-xxl-12'>
                                        <TripList />
                                    </div>
                                    : ""
                            }
                            {/* end::Col */}
                        </div>
                        {/* end::Row */}
                        {/* begin::Row */}
                        <div className='row gy-5 g-xl-8'>
                            {/* begin::Col */}
                            {
                                permission['api::cab']['controllers']['cab']['find']['enabled'] ?
                                    <div className='col'>
                                        <CabList />
                                    </div>
                                    : ""
                            }
                            {/* end::Col */}
                            {/* begin::Col */}
                            {
                                permission['api::driver']['controllers']['driver']['find']['enabled'] ?
                                    <div className='col'>
                                        <DriverList />
                                    </div>
                                    : ""
                            }
                            {/* end::Col */}
                        </div>
                        {/* end::Row */}
                        {/* begin::Row */}
                        <div className='row gy-5 g-xl-8'>
                            {/* begin::Col */}
                            {
                                permission['api::cab']['controllers']['cab']['find']['enabled'] ?
                                    <div className='col'>
                                        <ECabList />
                                    </div>
                                    : ""
                            }
                            {/* end::Col */}
                            {/* begin::Col */}
                            {
                                permission['api::driver']['controllers']['driver']['find']['enabled'] ?
                                    <div className='col'>
                                        <EDriverList />
                                    </div>
                                    : ""
                            }
                            {/* end::Col */}
                        </div>
                        {/* end::Row */}
                        {/* begin::Row */}
                        <div className='row gy-5 g-xl-8'>
                            {/* begin::Col */}
                            {
                                permission['api::trip-complain']['controllers']['trip-complain']['find']['enabled'] ?
                                    <div className='col'>
                                        <CustomerComplaintList />
                                    </div>
                                    : ""
                            }
                            {/* end::Col */}
                            {/* begin::Col */}
                            {
                                permission['api::trip-complain']['controllers']['trip-complain']['find']['enabled'] ?
                                    <div className='col'>
                                        <AgentComplaintList />
                                    </div>
                                    : ""
                            }
                            {/* end::Col */}
                        </div>
                        {/* end::Row */}
                        {/* begin::Row */}
                        <div className='row gy-5 g-xl-8'>
                            {/* begin::Col */}
                            {
                                permission['plugin::users-permissions']['controllers']['user']['find']['enabled'] ?
                                    <div className='col-md-6'>
                                        <AgentList />
                                    </div>
                                    : ""
                            }
                            {/* end::Col */}
                            {
                                permission['plugin::users-permissions']['controllers']['user']['find']['enabled'] ?
                                    <div className='col-md-6'>
                                        <CustomerList />
                                    </div>
                                    : ""
                            }
                            {/* end::Col */}
                        </div>
                        {/* end::Row */}
                        {/* begin::Row */}
                        <div className='row gy-5 g-xl-8'>
                            {/* begin::Col */}
                            {
                                permission['api::wallet']['controllers']['wallet']['find']['enabled'] ?
                                    <div className='col-xxl-12'>
                                        <SubscriptionList />
                                    </div>
                                    : ""
                            }
                            {/* end::Col */}

                        </div>

                        {/* begin::Row */}
                        <div className='row gy-5 g-xl-8'>
                            {/* begin::Col */}
                            {
                                permission['api::wallet']['controllers']['wallet']['find']['enabled'] ?
                                    <div className='col-xxl-12'>
                                        <ExpiredAgentList />
                                    </div>
                                    : ""
                            }
                            {/* end::Col */}

                        </div>

                        {/* end::Row */}
                        <div className='row gy-5 g-xl-8'>
                            {/* begin::Col */}
                            {
                                permission['api::wallet']['controllers']['wallet']['find']['enabled'] ?
                                    <div className='col-xxl-8'>
                                        <UnsubscribedUserList />
                                    </div>
                                    : ""
                            }
                            {/* end::Col */}
                            {/* begin::Col */}
                            {
                                permission['plugin::users-permissions']['controllers']['user']['find']['enabled'] ?
                                    <div className='col-xxl-4'>
                                        <InactiveAgentList />
                                    </div>
                                    : ""
                            }
                            {/* end::Col */}
                        </div>
                        {/* end::Row */}
                        {/* begin::Row */}
                        <div className='row gy-5 g-xl-8'>
                            {/* begin::Col */}
                            {
                                permission['api::trip']['controllers']['trip']['find']['enabled'] ?
                                    <div className='col-xxl-12'>
                                        <OnlinetripList />
                                    </div>
                                    : ""
                            }
                            {/* end::Col */}
                        </div>
                        {/* end::Row */}

                        {/* end::Row */}
                    </>
                    : ""}
        </>
    )
}

const DashboardWrapper = () => {
    const intl = useIntl()
    return (
        <>
            <PageTitle breadcrumbs={dashboardBreadCrumbs}>
                {intl.formatMessage({ id: 'MENU.DASHBOARD' })}
            </PageTitle>
            <DashboardPage />
        </>
    )
}

export { DashboardWrapper }
