/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect, useState } from "react";
import { KTSVG } from "../../../../../../_rashmicab/helpers";
import { ChatInner } from "./ChatInner";
import { Link } from "react-router-dom";
import { getById, updateEntry } from "../../core/_requests";
import { Complaint } from "../../core/_models";
import { Loading } from "../../components/loading";
import { useAuth } from "../../../../../modules/auth";
import { message } from '../../../../../services'
import { useNavigate } from 'react-router-dom'

type Props = {
  complain: any;
  setShowComplaint: any;
  creator: any;
};

const DrawerMessenger: FC<Props> = ({
  complain,
  setShowComplaint,
  creator,
}) => {
  const [complaint, setCurrentcomplaint] = useState<Complaint>({});
  const navigate = useNavigate();
  const element = document.querySelector(
    "#kt_drawer_chat_messenger_body .scroll-y"
  );
  const [isLoading, setLoading] = useState(true);
  const { auth } = useAuth();
  useEffect(() => {
    if (Number(complain.complain_id) > 0) {
      if (auth && auth.jwt) {
        getById(Number(complain.complain_id), auth.jwt).then((data) => {
          if (Object.keys(complaint).length < data.data.data.length) {
            if (element !== null) {
              element.classList.add("scrolling");
            }
          }
          setCurrentcomplaint(data.data.data);
        });
      }
    }
    if (Object.keys(complaint).length !== 0) {
      setLoading(false);
    }
  }, [complaint]);

  const closeComplaint = (complaintid) => {
    let _data = new Object();
    _data['status'] = 'closed'
    updateEntry(Number(complaintid), _data).then((response) => {
      message("Complain Closed Successfully !!", 'success')
      navigate("/");
    }).catch(({ response }) => {
      message(response.status === 400 ? response.data.error.message : response.data.message[0].messages[0].message, 'error')
    });
  }

  return (
    <div
      id="kt_drawer_chat"
      className="bg-body"
      data-kt-drawer="true"
      data-kt-drawer-name="chat"
      data-kt-drawer-activate="true"
      data-kt-drawer-overlay="true"
      data-kt-drawer-width="{default:'300px', 'md': '500px'}"
      data-kt-drawer-direction="end"
      data-kt-drawer-toggle="#kt_drawer_chat_toggle"
      data-kt-drawer-close="#kt_drawer_chat_close"
    >
      <div className="card w-100 rounded-0" id="kt_drawer_chat_messenger">
        <div className="card-header pe-5" id="kt_drawer_chat_messenger_header">
          <div className="card-title flex-fill">
            <div className="d-flex justify-content-center flex-column me-3">
              {creator.data !== null ? (
                typeof creator.user_id !== "undefined" ? (
                  <div className="d-flex">
                    <Link
                      to={`${creator.role_id !== null
                        ? creator.role_id === 4
                          ? "/app/agents/" + creator.user_id
                          : creator.role_id === 5
                            ? "/app/customers/" + creator.user_id
                            : "#"
                        : "#"
                        }`}
                      className="fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1 text-capitalize"
                    >
                      {`${creator.firstname !== null
                        ? creator.firstname + " " + creator.lastname
                        : ""
                        }`}
                    </Link>
                    <span className="fs-7 fw-bold text-gray-400 me-auto">{`${creator.role_id !== null
                      ? creator.role_id === 4
                        ? "(Agent)"
                        : "(Client)"
                      : ""
                      }`}</span>
                  </div>
                ) : (
                  ""
                )
              ) : (
                ""
              )}
              {typeof complain.complainmasters_subject !== "undefined" ? (
                <div className="me-auto">
                  {complain.complainmasters_subject
                    ? complain.complainmasters_subject
                    : "-"}
                </div>
              ) : (
                ""
              )}
            </div>
            {
              complain.complian_status !== 'closed' ?
                <button
                  className='btn btn-danger me-0 ms-auto'
                  type='button'
                  data-kt-element='close'
                  onClick={(e) => closeComplaint(complain.complain_id)}
                >
                  Close Complaint
                </button>
                : null
            }
          </div>

          <div className="card-toolbar">
            <div
              className="btn btn-sm btn-icon btn-active-light-primary"
              id="kt_drawer_chat_close"
              onClick={() => setShowComplaint(false)}
            >
              <KTSVG
                path="/media/icons/duotune/arrows/arr061.svg"
                className="svg-icon-2"
              />
            </div>
          </div>
        </div>

        <ChatInner
          isDrawer={true}
          currentcomplain={complain}
          complaintid={complain.complain_id}
          complaints={complaint}
          setCurrentcomplaint={setCurrentcomplaint}
        />
        {isLoading && <Loading />}
      </div>
    </div>
  );
};

export { DrawerMessenger };
