import { Column } from 'react-table'
import { InfoCell } from './InfoCell'
import { CustomHeader } from './CustomHeader'
import { PhoneCell } from './PhoneCell'
import { Customer } from '../../core/_models'
import { StatusCell } from './StatusCell'

const Columns: ReadonlyArray<Column<Customer>> = [
    {
        Header: (props) => <CustomHeader tableProps={props} title='Name' className='w-130px' />,
        id: 'firstname',
        Cell: ({ ...props }) => <InfoCell customer={props.data[props.row.index]} />,
    },
    {
        Header: (props) => <CustomHeader tableProps={props} title='Phone number' className='w-120px' />,
        accessor: 'phone',
        Cell: ({ ...props }) => <PhoneCell customer={props.data[props.row.index]} />,
    },
    {
        Header: (props) => <CustomHeader tableProps={props} title='Status' className='w-80px' />,
        accessor: 'status',
        Cell: ({ ...props }) => <StatusCell customer={props.data[props.row.index]} />,
    },
]

export { Columns }
