import { FC } from 'react'
import { UnsubscribedUser } from '../../core/_models'

type Props = {
    agent: UnsubscribedUser
}

const PhoneCell: FC<Props> = ({ agent }) => (
    <div className='d-flex align-items-center'>
        <div className='d-flex flex-column'>
            <span>{agent.phone === null ? '-' : agent.phone}</span>
        </div>
    </div>
)

export { PhoneCell }
