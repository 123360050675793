import { FC } from 'react'
import { Link } from 'react-router-dom'

type Props = {
    agent: any,
}

const InfoCell: FC<Props> = ({ agent }) => {
    return (
        <div className='d-flex align-items-center'>
            <div className='d-flex flex-column'>
                {
                    typeof agent !== "undefined" ?
                        <>
                            <Link className='text-gray-800 text-hover-primary mb-1'
                                to={`${agent !== null ?
                                    '/app/agents/' + agent.id
                                    : '#'}`}
                            >
                                {`${agent.attributes.firstname !== null ?
                                    agent.attributes.firstname + " " + agent.attributes.lastname
                                    : ''}`}
                            </Link>
                            <span>
                                {`${agent.attributes.email !== null ?
                                    agent.attributes.email
                                    : ''}`}
                            </span>
                        </>
                        : null
                }
            </div>
        </div >
    )
}

export { InfoCell }
