import { FC } from 'react'

type Props = {
    bids: any,
}

const BidsCell: FC<Props> = ({ bids }) => (
    <div className='d-flex align-items-center'>
        <div className='d-flex flex-column'>
            {
                typeof bids !== 'undefined' ?
                    < span > {bids.data !== undefined || bids.data !== null ? bids.data.length : ""}</span>
                    : "-"
            }
        </div>
    </div>
)

export { BidsCell }
