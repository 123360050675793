import axios, { AxiosResponse } from 'axios'
import { QueryResponse } from './_models'

const API_URL = process.env.REACT_APP_API_URL
const GET_USERS_URL = `${API_URL}/drivers?fields[0]=name&fields[1]=license&fields[2]=approve&populate[agent][fields][3]=id&populate[agent][fields][4]=firstname&populate[agent][fields][5]=lastname&populate[avtar][fields][6]=url&filters[deleted]=0`

const get = (query: string, token: string): Promise<QueryResponse> => {
    const table = query.split('&').map(pair => pair.split("="));
    const result = Object.fromEntries(table);

    var query_data = ``;
    query_data = `${query_data}&pagination[pageSize]=20`;
    query_data = `${query_data}&pagination[page]=1`;
    query_data = `${query_data}&sort=id:desc`;
    query_data = `${query_data}&filters[deleted]=0`;
    let __ddate = new Date();
    let datt = __ddate.getFullYear()+'-'+(__ddate.getMonth()+1).toString().padStart(2, "0")+'-'+__ddate.getDate().toString().padStart(2, "0");
    query_data = `${query_data}&filters[license_expiry][$gte]=${datt}`;


    return axios
        .get(`${GET_USERS_URL}${query_data}`, {
            headers: { "Authorization": `Bearer  ${token}` }
        })
        .then((d: AxiosResponse<QueryResponse>) => {
            if (result.pagesize === "all") {
                if (typeof d.data !== "undefined") {
                    if (typeof d.data.meta !== "undefined") {
                        if (typeof d.data.meta.pagination !== "undefined") {
                            if (typeof d.data.meta.pagination.pageSize !== "undefined") {
                                d.data.meta.pagination.pageSize = "all";
                            }
                        }
                    }
                }
            }
            return d.data;
        }
        )
}

export { get }
