import { Column } from 'react-table'
import { MessagesCell } from './MessagesCell'
import { UserCell } from './UserCell'
import { InfoCell } from './InfoCell'
import { ActionsCell } from './ActionsCell'
import { CustomHeader } from './CustomHeader'
import { Complaint } from '../../core/_models'

const Columns: ReadonlyArray<Column<Complaint>> = [
    {
        Header: (props) => <CustomHeader tableProps={props} title='Complain' className='min-w-125px' />,
        id: 'id',
        Cell: ({ ...props }) => <InfoCell complain={props.data[props.row.index]} />,
    },
    {
        Header: (props) => <CustomHeader tableProps={props} title='Creator' className='min-w-125px' />,
        id: 'creator',
        Cell: ({ ...props }) => <UserCell creator={props.data[props.row.index]} trip={props.data[props.row.index]} />,
    },
    {
        Header: (props) => (
            <CustomHeader tableProps={props} title='Message' className='min-w-100px' />
        ),
        id: 'message',
        Cell: ({ ...props }) => <MessagesCell message={props.data[props.row.index]} />,
    },
    {
        Header: (props) => (
            <CustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
        ),
        id: 'actions',
        Cell: ({ ...props }) => <ActionsCell complain={props.data[props.row.index]} creator={props.data[props.row.index]}  />,
    },
]

export { Columns }
