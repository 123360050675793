import { FC } from 'react'
import { Onlinetrip } from '../../core/_models'

type Props = {
    trip: Onlinetrip,
    id: any
}

const DateCell: FC<Props> = ({ trip, id }) => (
    <div className='d-flex align-items-center'>
        <div className='d-flex flex-column'>
            {`${trip.datetime ?
                new Intl.DateTimeFormat('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'  }).format(new Date(trip.datetime))
                : ''}`}
            <span>{`${trip.return ?

                new Intl.DateTimeFormat('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'  }).format(new Date(trip.return))
                : ''}`}</span>
        </div>
    </div>
)

export { DateCell }
