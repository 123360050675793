/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import { FC } from 'react'
import { Link } from 'react-router-dom'
import { useAuth } from '../../../../app/modules/auth'
import { toAbsoluteUrl } from '../../../helpers'

const HeaderUserMenu: FC = () => {
  const { currentUser, logout } = useAuth();
  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            {currentUser?.image != null ? (
              <img alt='Logo' src={toAbsoluteUrl(`${process.env.REACT_APP_PREVIEW_REACT_URL}/public${currentUser?.image?.url}`)} />
            ) : <div
              className={clsx(
                'symbol-label fs-3',
                `bg-light-undefined text-undefined`
              )}
            >
              {currentUser?.firstname.match(/\b(\w)/g)}
            </div>}
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              <Link to={'/profile'} className='menu-link px-0 py-0 '>
                {currentUser?.firstname} {currentUser?.lastname}
              </Link>
              <span className='badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2'>{currentUser?.role?.name}</span>
            </div>
            <a href={`mailto:${currentUser?.email}`} className='fw-bold text-muted text-hover-primary fs-7'>
              {currentUser?.email}
            </a>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>

      <div className='menu-item px-5'>
        <Link to={'/profile/edit'} className='menu-link px-5'>
          Edit Profile
        </Link>
      </div>
      {currentUser.role.id === 1 ?
        <div className='menu-item px-5'>
          <Link to={'/permission'} className='menu-link px-5'>
            Permission
          </Link>
        </div>
        : ""
      }

      <div className='separator my-2'></div>

      {/* <Languages /> */}
      {currentUser.role.id === 1 ?
        <div className='menu-item px-5 my-1'>
          <Link to='/setting' className='menu-link px-5'>
            Settings
          </Link>
        </div>
        : ""
      }

      <div className='menu-item px-5'>
        <a onClick={logout} className='menu-link px-5'>
          Sign Out
        </a>
      </div>
    </div>
  )
}

export { HeaderUserMenu }
