/* eslint-disable react-hooks/exhaustive-deps */
import "react-datepicker/dist/react-datepicker.css"
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import { useState, useEffect, ChangeEvent } from 'react'
import { get, updateEntry, uploadImage } from './core/_request'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import clsx from 'clsx'
import { toAbsoluteUrl } from '../../../_rashmicab/helpers'
import { message } from '../../services'
import { useAuth } from '../../modules/auth'
import DatePicker from 'react-datepicker'

export interface Setting {
  id: number
  title: string
  email: string
  contact: string,
  address: string,
  pdf_prefix: string,
  pdf_note: string,
  website: string,
  gst_no: string,
  gst_rate: number,
  logo: any
  background: any
  favicon: any
  normal_trip_closed_time: string
  auto_closed: boolean
}

const schema = Yup.object().shape({
  email: Yup.string().trim().strict(true)
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  title: Yup.string().trim().strict(true).required('Title is required'),
  address: Yup.string().trim().strict(true).required('Address is required'),
  pdf_note: Yup.string().trim().strict(true).required('PDF Note is required'),
  pdf_prefix: Yup.string().trim().strict(true).required('PDF Prefix is required'),
  website: Yup.string()
    .matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      'Enter correct url!'
    )
    .required('Please enter website'),
  gst_no: Yup.string().trim().strict(true).required('GST NO. is required'),
  gst_rate: Yup.number().required('GST Rate is required'),
  contact: Yup.string().required('Contact is required'),
  auto_closed: Yup.boolean(),
  normal_trip_closed_time: Yup.string().when('auto_closed', {
    is: (auto_closed) => auto_closed === true,
    then: Yup.string().required('Auto Closed Trip Time is required'),
  }),
})
const blankImg = toAbsoluteUrl('/media/svg/files/blank-image.svg')
const Settings = () => {
  const initalSetting = {
    id: 0,
    title: '',
    email: '',
    contact: '',
    logo: undefined,
    background: undefined,
    favicon: undefined,
    normal_trip_closed_time: '',
    auto_closed: false,
    website: '',
    address: '',
    pdf_note: '',
    pdf_prefix: '',
    gst_no: '',
    gst_rate: 0,
  }
  const [setting, setSetting] = useState<Setting>()
  const [loading, setLoading] = useState(false)
  const { setLightbox } = useAuth()
  const { auth } = useAuth()
  const __Loading = () => {
    const styles = {
      borderRadius: '0.475rem',
      boxShadow: '0 0 50px 0 rgb(82 63 105 / 15%)',
      backgroundColor: '#fff',
      color: '#7e8299',
      fontWeight: '500',
      margin: '0',
      width: 'auto',
      padding: '1rem 2rem',
      top: 'calc(50% - 2rem)',
      left: 'calc(50% - 4rem)',
    }

    return <div style={{ ...styles, position: 'absolute', textAlign: 'center' }}>Processing...</div>
  }

  const formik = useFormik<Setting>({
    initialValues: initalSetting,
    enableReinitialize: true,
    validationSchema: schema,
    onSubmit: async (values, { setSubmitting }) => {
      let updated = Object()
      if (values.logo === null) {
        setSubmitting(false)
      } else {
        updated.logo = values.logo.data.id
      }
      if (values.background === null) {
        setSubmitting(false)
      } else {
        updated.background = values.background.data.id
      }
      if (values.favicon === null) {
        setSubmitting(false)
      } else {
        updated.favicon = values.favicon.data.id
      }
      updated.title = values.title
      updated.contact = values.contact

      if (values.auto_closed) {
        var _normal_trip_closed_time = new Date(values.normal_trip_closed_time);
        if (typeof _normal_trip_closed_time !== 'string') {
          updated.normal_trip_closed_time = ("0" + _normal_trip_closed_time.getHours()).slice(-2) + ':' + ("0" + _normal_trip_closed_time.getMinutes()).slice(-2) + ':' + ("0" + _normal_trip_closed_time.getSeconds()).slice(-2) + "." + ("0" + _normal_trip_closed_time.getMilliseconds()).slice(-3)
        } else {
          updated.normal_trip_closed_time = values.normal_trip_closed_time
        }
      }
      updated.auto_closed = values.auto_closed
      updated.email = values.email
      updated.address = values.address
      updated.website = values.website
      updated.pdf_prefix = values.pdf_prefix
      updated.gst_no = values.gst_no
      updated.pdf_note = values.pdf_note
      updated.email = values.email
      updated.gst_rate = values.gst_rate
      setSubmitting(true)
      setLoading(true)
      if (auth && auth.jwt) {
        updateEntry(updated, auth.jwt)
          .then((response) => {
            setLoading(false)
            message('Updated Successfully!', 'success')
          })
          .catch(({ response }) => {
            setLoading(false)
            message(
              response.status === 400
                ? response.data.error.message
                : response.data.message[0].messages[0].message,
              'error'
            )
          })
      } else {
        updateEntry(updated, '')
          .then((response) => {
            setLoading(false)
            message('Updated Successfully!', 'success')
          })
          .catch(({ response }) => {
            setLoading(false)
            message(
              response.status === 400
                ? response.data.error.message
                : response.data.message[0].messages[0].message,
              'error'
            )
          })
      }
    },
  })

  const { values, setFieldValue } = formik
  useEffect(() => {
    async function getdt() {
      if (typeof setting === 'undefined') {
        if (auth && auth.jwt) {
          await get(auth.jwt)
            .then((response) => {
              if (response.status === 200) {
                var normal_trip_closed = response.data.data.attributes.normal_trip_closed_time.split('.')[0]
                var normal_trip_closed_ = normal_trip_closed.split(':')
                var normal_trip_closed_time_hr = normal_trip_closed_[0]
                var normal_trip_closed_time_min = normal_trip_closed_[1]
                var normal_trip_closed_time_sec = normal_trip_closed_[2]
                var normal_trip_closed_time = new Date()
                normal_trip_closed_time.setHours(normal_trip_closed_time_hr)
                normal_trip_closed_time.setMinutes(normal_trip_closed_time_min)
                normal_trip_closed_time.setSeconds(normal_trip_closed_time_sec)
                setSetting(response.data.data.attributes)
                setFieldValue('logo', response.data.data.attributes.logo)
                setFieldValue('background', response.data.data.attributes.background)
                setFieldValue('favicon', response.data.data.attributes.favicon)
                setFieldValue('title', response.data.data.attributes.title)
                setFieldValue('contact', response.data.data.attributes.contact)
                setFieldValue('address', response.data.data.attributes.address)
                setFieldValue('website', response.data.data.attributes.website)
                setFieldValue('pdf_prefix', response.data.data.attributes.pdf_prefix)
                setFieldValue('gst_no', response.data.data.attributes.gst_no)
                setFieldValue('pdf_note', response.data.data.attributes.pdf_note)
                setFieldValue('email', response.data.data.attributes.email)
                setFieldValue('gst_rate', response.data.data.attributes.gst_rate)
                setFieldValue('auto_closed', response.data.data.attributes.auto_closed)
                setFieldValue('normal_trip_closed_time', normal_trip_closed_time)
              }
            })
            .catch((data) => { })
        } else {
          await get('')
            .then((response) => {
              if (response.status === 200) {
                var normal_trip_closed = response.data.data.attributes.normal_trip_closed_time.split('.')[0]
                var normal_trip_closed_ = normal_trip_closed.split(':')
                var normal_trip_closed_time_hr = normal_trip_closed_[0]
                var normal_trip_closed_time_min = normal_trip_closed_[1]
                var normal_trip_closed_time_sec = normal_trip_closed_[2]
                var normal_trip_closed_time = new Date()
                normal_trip_closed_time.setHours(normal_trip_closed_time_hr)
                normal_trip_closed_time.setMinutes(normal_trip_closed_time_min)
                normal_trip_closed_time.setSeconds(normal_trip_closed_time_sec)
                setSetting(response.data.data.attributes)
                setFieldValue('logo', response.data.data.attributes.logo)
                setFieldValue('background', response.data.data.attributes.background)
                setFieldValue('favicon', response.data.data.attributes.favicon)
                setFieldValue('title', response.data.data.attributes.title)
                setFieldValue('contact', response.data.data.attributes.contact)
                setFieldValue('address', response.data.data.attributes.address)
                setFieldValue('website', response.data.data.attributes.website)
                setFieldValue('pdf_prefix', response.data.data.attributes.pdf_prefix)
                setFieldValue('gst_no', response.data.data.attributes.gst_no)
                setFieldValue('gst_rate', response.data.data.attributes.gst_rate)
                setFieldValue('pdf_note', response.data.data.attributes.pdf_note)
                setFieldValue('email', response.data.data.attributes.email)
                setFieldValue('auto_closed', response.data.data.attributes.auto_closed)
                setFieldValue('normal_trip_closed_time', normal_trip_closed_time)
              }
            })
            .catch((data) => { })
        }
      }
    }
    getdt()
  })
  const handleFileChange = (e: ChangeEvent<HTMLInputElement>, field: string) => {
    if (e.target.files.length) {
      if (auth && auth.jwt) {
        uploadImage(e.target.files[0], auth.jwt)
          .then((response) => {
            let _onj = Object()
            _onj['data'] = {}
            _onj['data']['attributes'] = response.data[0]
            _onj['data']['id'] = response.data[0].id
            if (field === 'logo') {
              setFieldValue('logo', _onj)
            }
            if (field === 'background') {
              setFieldValue('background', _onj)
            }
            if (field === 'favicon') {
              setFieldValue('favicon', _onj)
            }
            message('image uploaded!', 'success')
          })
          .catch(({ err }) => {
            message('Upload failed', 'error')
          })
      } else {
        uploadImage(e.target.files[0], '')
          .then((response) => {
            let _onj = Object()
            _onj['data'] = {}
            _onj['data']['attributes'] = response.data[0]
            _onj['data']['id'] = response.data[0].id
            if (field === 'logo') {
              setFieldValue('logo', _onj)
            }
            if (field === 'background') {
              setFieldValue('background', _onj)
            }
            if (field === 'favicon') {
              setFieldValue('favicon', _onj)
            }
            message('image uploaded!', 'success')
          })
          .catch(({ err }) => {
            message('Upload failed', 'error')
          })
      }
    }
  }
  const removeFile = (e: any, field: string) => {
    if (field === 'logo') {
      setFieldValue('logo', 'null')
    }
    if (field === 'background') {
      setFieldValue('background', 'null')
    }
    if (field === 'favicon') {
      setFieldValue('favicon', 'null')
    }
  }
  const lightBoxImage = (image: any, index: any) => {
    var toggler = true
    var images = '' || []

    if (typeof image !== 'string') {
      images = image.map(({ url }) => {
        var lightboxData1 = Object()
        lightboxData1.src = `${process.env.REACT_APP_PREVIEW_REACT_URL}/public${url}`
        return lightboxData1
      })
    } else {
      var lightboxData1 = Object()
      lightboxData1.src = image
      images = [lightboxData1]
    }
    var lightboxData = Object()
    lightboxData.toggler = toggler
    lightboxData.sources = images
    lightboxData.index = index
    setLightbox(lightboxData)
  }

  return (
    <div className='row'>
      <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
        <div className='card-header cursor-pointer'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Settings</h3>
          </div>
        </div>
        {typeof setting !== 'undefined' ? (
          <>
            <form
              id='kt_modal_add_form'
              className='mb-5 mb-xl-10 form'
              onSubmit={formik.handleSubmit}
              noValidate
            >
              <div className='card-body border-top p-9 row d-flex flex-column scroll-y me-n7 pe-7'>
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>Images</label>
                  <input
                    {...formik.getFieldProps('logo')}
                    type='hidden'
                    name='logo'
                    autoComplete='off'
                    disabled={formik.isSubmitting || loading}
                  />
                  <input
                    {...formik.getFieldProps('background')}
                    type='hidden'
                    name='background'
                    autoComplete='off'
                    disabled={formik.isSubmitting || loading}
                  />
                  <input
                    {...formik.getFieldProps('favicon')}
                    type='hidden'
                    name='favicon'
                    autoComplete='off'
                    disabled={formik.isSubmitting || loading}
                  />
                  <div className='col-lg-8 row'>
                    <div className='col-lg-4'>
                      <div className='col-form-label fw-bold fs-6'>Logo</div>
                      <div
                        className={
                          typeof values.logo !== 'undefined' && values.logo !== 'null'
                            ? values.logo.data.attributes.url === ''
                              ? 'image-input image-input-outline image-input-empty'
                              : 'image-input image-input-outline'
                            : 'image-input image-input-outline image-input-empty'
                        }
                        data-kt-image-input='true'
                        style={{ backgroundImage: `url('${blankImg}')` }}
                      >
                        {typeof values.logo !== 'undefined' && values.logo !== 'null' ? (
                          <div
                            className='image-input-wrapper w-125px h-125px'
                            style={{
                              backgroundImage: `url('${process.env.REACT_APP_PREVIEW_REACT_URL}/public${values.logo.data.attributes.url}')`,
                            }}
                          ></div>
                        ) : (
                          <div
                            className='image-input-wrapper w-125px h-125px'
                            style={{ backgroundImage: `url('${blankImg}')` }}
                          ></div>
                        )}
                        <label
                          className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                          data-kt-image-input-action='change'
                          data-bs-toggle='tooltip'
                          title='Change logo'
                        >
                          <i className='bi bi-pencil-fill fs-7'></i>
                          <input
                            type='file'
                            name='logo'
                            accept='.png, .jpg, .jpeg'
                            onChange={(event) => handleFileChange(event, 'logo')}
                          />
                        </label>
                        <span
                          className='remove_image btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                          data-kt-image-input-action={
                            typeof values.logo !== 'undefined' && values.logo !== 'null'
                              ? ''
                              : 'cancel'
                          }
                          data-bs-toggle='tooltip'
                          title='Cancel logo'
                          onClick={(event) => removeFile(event, 'logo')}
                        >
                          <i className='bi bi-x fs-2'></i>
                        </span>
                        {typeof values.logo !== 'undefined' && values.logo !== 'null' ? (
                          <span
                            className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                            data-kt-image-input-action={
                              typeof values.logo !== 'undefined' && values.logo !== 'null'
                                ? ''
                                : 'cancel'
                            }
                            data-bs-toggle='tooltip'
                            title='Zoom image'
                            onClick={() =>
                              lightBoxImage(
                                `${process.env.REACT_APP_PREVIEW_REACT_URL}/public${values.logo.data.attributes.url}`,
                                0
                              )
                            }
                          >
                            <i className='bi bi-search fs-8'></i>
                          </span>
                        ) : (
                          ''
                        )}
                      </div>
                      <div className='form-text'>Allowed file types: png, jpg, jpeg.</div>
                      {(typeof values.logo === 'undefined' || values.logo === 'null') && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>Logo Required!!</span>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className='col-lg-4'>
                      <div className='col-form-label fw-bold fs-6'>Favicon</div>
                      <div
                        className={
                          typeof values.favicon !== 'undefined' && values.favicon !== 'null'
                            ? values.favicon.data.attributes.url === ''
                              ? 'image-input image-input-outline image-input-empty'
                              : 'image-input image-input-outline'
                            : 'image-input image-input-outline image-input-empty'
                        }
                        data-kt-image-input='true'
                        style={{ backgroundImage: `url('${blankImg}')` }}
                      >
                        {typeof values.favicon !== 'undefined' && values.favicon !== 'null' ? (
                          <div
                            className='image-input-wrapper w-125px h-125px'
                            style={{
                              backgroundImage: `url('${process.env.REACT_APP_PREVIEW_REACT_URL}/public${values.favicon.data.attributes.url}')`,
                            }}
                          ></div>
                        ) : (
                          <div
                            className='image-input-wrapper w-125px h-125px'
                            style={{ backgroundImage: `url('${blankImg}')` }}
                          ></div>
                        )}
                        <label
                          className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                          data-kt-image-input-action='change'
                          data-bs-toggle='tooltip'
                          title='Change favicon'
                        >
                          <i className='bi bi-pencil-fill fs-7'></i>
                          <input
                            type='file'
                            name='favicon'
                            accept='.png, .jpg, .jpeg'
                            onChange={(event) => handleFileChange(event, 'favicon')}
                          />
                        </label>
                        <span
                          className='remove_image btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                          data-kt-image-input-action={
                            typeof values.favicon !== 'undefined' && values.favicon !== 'null'
                              ? ''
                              : 'cancel'
                          }
                          data-bs-toggle='tooltip'
                          title='Cancel favicon'
                          onClick={(event) => removeFile(event, 'favicon')}
                        >
                          <i className='bi bi-x fs-2'></i>
                        </span>
                        {typeof values.favicon !== 'undefined' && values.favicon !== 'null' ? (
                          <span
                            className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                            data-kt-image-input-action={
                              typeof values.favicon !== 'undefined' && values.favicon !== 'null'
                                ? ''
                                : 'cancel'
                            }
                            data-bs-toggle='tooltip'
                            title='Zoom image'
                            onClick={() =>
                              lightBoxImage(
                                `${process.env.REACT_APP_PREVIEW_REACT_URL}/public${values.favicon.data.attributes.url}`,
                                0
                              )
                            }
                          >
                            <i className='bi bi-search fs-8'></i>
                          </span>
                        ) : (
                          ''
                        )}
                      </div>
                      <div className='form-text'>Allowed file types: png, jpg, jpeg.</div>
                      {(typeof values.favicon === 'undefined' || values.favicon === 'null') && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>Favicon Required!!</span>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className='col-lg-4'>
                      <div className='col-form-label fw-bold fs-6'>Background</div>
                      <div
                        className={
                          typeof values.background !== 'undefined' && values.background !== 'null'
                            ? values.background.data.attributes.url === ''
                              ? 'image-input image-input-outline image-input-empty'
                              : 'image-input image-input-outline'
                            : 'image-input image-input-outline image-input-empty'
                        }
                        data-kt-image-input='true'
                        style={{ backgroundImage: `url('${blankImg}')` }}
                      >
                        {typeof values.background !== 'undefined' &&
                          values.background !== 'null' ? (
                          <div
                            className='image-input-wrapper w-125px h-125px'
                            style={{
                              backgroundImage: `url('${process.env.REACT_APP_PREVIEW_REACT_URL}/public${values.background.data.attributes.url}')`,
                            }}
                          ></div>
                        ) : (
                          <div
                            className='image-input-wrapper w-125px h-125px'
                            style={{ backgroundImage: `url('${blankImg}')` }}
                          ></div>
                        )}
                        <label
                          className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                          data-kt-image-input-action='change'
                          data-bs-toggle='tooltip'
                          title='Change background'
                        >
                          <i className='bi bi-pencil-fill fs-7'></i>
                          <input
                            type='file'
                            name='background'
                            accept='.png, .jpg, .jpeg'
                            onChange={(event) => handleFileChange(event, 'background')}
                          />
                        </label>
                        <span
                          className='remove_image btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                          data-kt-image-input-action={
                            typeof values.background !== 'undefined' && values.background !== 'null'
                              ? ''
                              : 'cancel'
                          }
                          data-bs-toggle='tooltip'
                          title='Cancel background'
                          onClick={(event) => removeFile(event, 'background')}
                        >
                          <i className='bi bi-x fs-2'></i>
                        </span>
                        {typeof values.background !== 'undefined' &&
                          values.background !== 'null' ? (
                          <span
                            className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                            data-kt-image-input-action={
                              typeof values.background !== 'undefined' &&
                                values.background !== 'null'
                                ? ''
                                : 'cancel'
                            }
                            data-bs-toggle='tooltip'
                            title='Zoom image'
                            onClick={() =>
                              lightBoxImage(
                                `${process.env.REACT_APP_PREVIEW_REACT_URL}/public${values.background.data.attributes.url}`,
                                0
                              )
                            }
                          >
                            <i className='bi bi-search fs-8'></i>
                          </span>
                        ) : (
                          ''
                        )}
                      </div>
                      <div className='form-text'>Allowed file types: png, jpg, jpeg.</div>
                      {(typeof values.background === 'undefined' ||
                        values.background === 'null') && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>Background Required!!</span>
                            </div>
                          </div>
                        )}
                    </div>
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label required fw-bold fs-6'>Title</label>
                  <div className='col-lg-8'>
                    <input
                      placeholder='Title'
                      {...formik.getFieldProps('title')}
                      className={clsx(
                        'form-control form-control-solid mb-3 mb-lg-0',
                        {
                          'is-invalid': formik.errors.title,
                        },
                        { 'is-valid': !formik.errors.title }
                      )}
                      name='title'
                      autoComplete='off'
                      disabled={formik.isSubmitting || loading}
                    />
                    {formik.touched.title && formik.errors.title && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.title}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label required fw-bold fs-6'>Email</label>
                  <div className='col-lg-8'>
                    <input
                      placeholder='Email'
                      {...formik.getFieldProps('email')}
                      className={clsx(
                        'form-control form-control-solid mb-3 mb-lg-0',
                        {
                          'is-invalid': formik.errors.email,
                        },
                        { 'is-valid': !formik.errors.email }
                      )}
                      type='email'
                      name='email'
                      autoComplete='off'
                      disabled={formik.isSubmitting || loading}
                    />
                    {formik.touched.email && formik.errors.email && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.email}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                    Invoice PDF Contact
                  </label>
                  <div className='col-lg-8'>
                    <input
                      placeholder='Invoice PDF Contact'
                      {...formik.getFieldProps('contact')}
                      className={clsx(
                        'form-control form-control-solid mb-3 mb-lg-0',
                        {
                          'is-invalid': formik.errors.contact,
                        },
                        { 'is-valid': !formik.errors.contact }
                      )}
                      name='contact'
                      autoComplete='off'
                      disabled={formik.isSubmitting || loading}
                    />
                    {formik.touched.contact && formik.errors.contact && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.contact}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                    Invoice PDF Website
                  </label>
                  <div className='col-lg-8'>
                    <input
                      placeholder='Invoice PDF Website'
                      {...formik.getFieldProps('website')}
                      className={clsx(
                        'form-control form-control-solid mb-3 mb-lg-0',
                        {
                          'is-invalid': formik.errors.website,
                        },
                        { 'is-valid': !formik.errors.website }
                      )}
                      name='website'
                      autoComplete='off'
                      disabled={formik.isSubmitting || loading}
                    />
                    {formik.touched.website && formik.errors.website && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.website}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                    Invoice PDF Address
                  </label>
                  <div className='col-lg-8'>
                    <textarea
                      placeholder='Invoice PDF Address'
                      {...formik.getFieldProps('address')}
                      className={clsx(
                        'form-control form-control-solid mb-3 mb-lg-0',
                        {
                          'is-invalid': formik.errors.address,
                        },
                        { 'is-valid': !formik.errors.address }
                      )}
                      name='address'
                      autoComplete='off'
                      disabled={formik.isSubmitting || loading}
                    />
                    {formik.touched.address && formik.errors.address && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.address}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                    Invoice PDF Prefix
                  </label>
                  <div className='col-lg-8'>
                    <input
                      placeholder='Invoice PDF Prefix'
                      {...formik.getFieldProps('pdf_prefix')}
                      className={clsx(
                        'form-control form-control-solid mb-3 mb-lg-0',
                        {
                          'is-invalid': formik.errors.pdf_prefix,
                        },
                        { 'is-valid': !formik.errors.pdf_prefix }
                      )}
                      name='pdf_prefix'
                      autoComplete='off'
                      disabled={formik.isSubmitting || loading}
                    />
                    {formik.touched.pdf_prefix && formik.errors.pdf_prefix && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.pdf_prefix}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                    Invoice PDF Note
                  </label>
                  <div className='col-lg-8'>
                    <textarea
                      placeholder='Invoice PDF Note'
                      {...formik.getFieldProps('pdf_note')}
                      className={clsx(
                        'form-control form-control-solid mb-3 mb-lg-0',
                        {
                          'is-invalid': formik.errors.pdf_note,
                        },
                        { 'is-valid': !formik.errors.pdf_note }
                      )}
                      name='pdf_note'
                      autoComplete='off'
                      disabled={formik.isSubmitting || loading}
                    />
                    {formik.touched.pdf_note && formik.errors.pdf_note && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.pdf_note}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                    Invoice PDF  GST NO.
                  </label>
                  <div className='col-lg-8'>
                    <input
                      placeholder='Invoice PDF GST NO.'
                      {...formik.getFieldProps('gst_no')}
                      className={clsx(
                        'form-control form-control-solid mb-3 mb-lg-0',
                        {
                          'is-invalid': formik.errors.gst_no,
                        },
                        { 'is-valid': !formik.errors.gst_no }
                      )}
                      name='gst_no'
                      autoComplete='off'
                      disabled={formik.isSubmitting || loading}
                    />
                    {formik.touched.gst_no && formik.errors.gst_no && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.gst_no}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                    Invoice PDF  GST Rate
                  </label>
                  <div className='col-lg-8'>
                    <input
                      placeholder='Invoice PDF GST Rate'
                      {...formik.getFieldProps('gst_rate')}
                      className={clsx(
                        'form-control form-control-solid mb-3 mb-lg-0',
                        {
                          'is-invalid': formik.errors.gst_rate,
                        },
                        { 'is-valid': !formik.errors.gst_rate }
                      )}
                      name='gst_rate'
                      autoComplete='off'
                      disabled={formik.isSubmitting || loading}
                    />
                    {formik.touched.gst_rate && formik.errors.gst_rate && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.gst_rate}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                    Auto Closed Trip ?
                  </label>
                  <div className='col-lg-8'>
                    <div className='form-check form-check-solid form-switch fv-row'>
                      <input
                        className='form-check-input w-45px h-30px'
                        type='checkbox'
                        id={'auto_closed'}
                        defaultChecked={values.auto_closed}
                        onChange={(e) => {
                          setFieldValue('auto_closed', e)
                        }}
                        {...formik.getFieldProps('auto_closed')}
                      />
                      <label className='form-check-label'></label>
                    </div>
                  </div>
                </div>
                <div className={`row mb-6 ${values.auto_closed ? 'normal_trip_closed_time' : 'd-none'}`}>
                  <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                    Auto Closed Trip Time
                  </label>
                  <div className='col-lg-8'>
                    <DatePicker
                      selected={values.normal_trip_closed_time !== '' ? new Date(values.normal_trip_closed_time) : null}
                      onChange={(e) => setFieldValue('normal_trip_closed_time', e)}
                      showTimeSelect
                      showTimeSelectOnly
                      timeFormat="HH:mm"
                      className='form-control form-control-solid mb-3 mb-lg-0'
                      timeIntervals={1}
                      timeCaption="time"
                      dateFormat="HH:mm"
                    />
                    {formik.touched.normal_trip_closed_time && formik.errors.normal_trip_closed_time && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.normal_trip_closed_time}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className='text-center card-footer d-flex justify-content-end py-6 px-9'>
                <button
                  type='submit'
                  className='btn btn-primary'
                  disabled={loading || formik.isSubmitting || !formik.isValid || !formik.touched}
                >
                  <span className='indicator-label'>Submit</span>
                  {(formik.isSubmitting || loading) && (
                    <span className='indicator-progress'>
                      Please wait...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            </form>
            {(formik.isSubmitting || loading) && __Loading()}

            {/* <div className='card-footer d-flex justify-content-end py-6 px-9'>
                    <button type='button' onClick={(e)=>saveSetting()} className='btn btn-primary' disabled={loading}>
                        {!loading && 'Save Changes'}
                        {loading && (
                        <span className='indicator-progress' style={{display: 'block'}}>
                            Please wait...{' '}
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                        )}
                    </button>
                </div> */}
          </>
        ) : (
          ''
        )}
      </div>
    </div>
  )
}

export { Settings }
