import clsx from 'clsx'
import { FC } from 'react'
import { Link } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../../../../_rashmicab/helpers'
import { Cab } from '../../core/_models'

type Props = {
    cab: Cab,
    id: any
}

const InfoCell: FC<Props> = ({ cab, id }) => {
    var agentid = '';
    if (typeof cab.agent !== "undefined") {
        agentid = '?agentid=' + cab.agent.data.id;
    }
    return (
        <div className='d-flex align-items-center'>
            <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
                <Link className='' to={'/app/cabs/' + id + agentid} >
                    {cab.mainimage !== null && cab.mainimage.data !== null ? (
                        <div className='symbol-label'>
                            <img src={toAbsoluteUrl(`${process.env.REACT_APP_PREVIEW_REACT_URL}/public${cab.mainimage?.data?.attributes?.url}`)} alt={cab.name} className='w-100' />
                        </div>
                    ) : (
                        <div
                            className={clsx(
                                'symbol-label fs-3',
                                `bg-light-undefined`,
                                `text-undefined`
                            )}
                        >
                            {cab.name ?
                                cab.name.charAt(0).toUpperCase() : ""
                            }
                        </div>
                    )}
                </Link>
            </div>
            <div className='d-flex flex-column'>
                <Link className='text-gray-800 text-hover-primary mb-1' to={'/app/cabs/' + id + agentid} >
                    {`${cab.name ? cab.name : ''}`} {`${typeof cab.cabtype !== 'undefined' ? cab.cabtype !== null ? cab.cabtype.data !== undefined || cab.cabtype.data !== null ? '(' + cab.cabtype.data?.attributes.title + ')' : '' : '' : ''}`}
                </Link>
                {
                    typeof cab.agent !== "undefined" ?
                        typeof cab.agent.data !== "undefined" ?
                            cab.agent.data !== null ?
                                < span > {cab.agent?.data?.attributes?.firstname + " " + cab.agent.data.attributes.lastname}
                                </span>
                                : ""
                            : ""
                        : ""
                }
                {
                    typeof cab.insurance_expiry !== "undefined" ?

                        < span > {cab.insurance_expiry ?
                            new Date(cab.insurance_expiry).getDate().toString().padStart(2, "0") + '-' + (new Date(cab.insurance_expiry).getMonth() + 1).toString().padStart(2, "0") + '-' + new Date(cab.insurance_expiry).getFullYear()

                            : ''}
                        </span>
                        : ""

                }
            </div>
        </div >
    )
}

export { InfoCell }
