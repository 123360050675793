/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { AsideDefault } from './components/aside/AsideDefault'
import { Footer } from './components/Footer'
import { HeaderWrapper } from './components/header/HeaderWrapper'
import { ScrollTop } from './components/ScrollTop'
import { Content } from './components/Content'
import { PageDataProvider } from './core'
import { useLocation } from 'react-router-dom'
import { MenuComponent } from '../assets/ts/components'
import { useAuth } from '../../app/modules/auth/core/Auth';
import { getPermission } from '../../app/modules/auth/';

const MasterLayout = () => {
  const { currentUser, setPermission } = useAuth()

  const location = useLocation()
  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [])

  useEffect(() => {

    getPermission(currentUser.role.id).then((data) => {
      setPermission(data.data.role.permissions)
    });
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)

  }, [location.key])

  return (
    <PageDataProvider>
      <div className='d-flex flex-column flex-root'>
        {/* begin::Page */}
        <div className='page d-flex flex-row flex-column-fluid'>
          <AsideDefault />
          {/* begin::Wrapper */}
          <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
            <HeaderWrapper />

            {/* begin::Content */}
            <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
              <Content>
                <Outlet />
              </Content>
            </div>
            {/* end::Content */}
            <Footer />
          </div>
          {/* end::Wrapper */}
        </div>
        {/* end::Page */}
      </div>
      <ScrollTop />
    </PageDataProvider>
  )
}

export { MasterLayout }
