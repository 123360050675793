import { FC } from 'react'

type Props = {
    user: any,
}

const UserCell: FC<Props> = ({ user }) => (
    <div className='d-flex align-items-center'>
        <div className='d-flex flex-column'>
            <div className='text-gray-800 text-hover-primary mb-1'>
                {`${user.online_trip_customer_name !== null ?
                    user.online_trip_customer_name
                    : ''}`}
            </div>
            <span>{`${user.online_trip_customer_number !== null ?
                user.online_trip_customer_number
                : ''}`}</span>
        </div>
    </div>
)

export { UserCell }
