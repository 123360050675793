import { toAbsoluteUrl } from '../../../helpers'
import { useAuth } from '../../../../app/modules/auth'
import { HeaderUserMenu } from '../../../partials'

const AsideFooter = () => {
  const { currentUser } = useAuth();
  return (
    <div
      className='aside-footer d-flex flex-column align-items-center flex-column-auto'
      id='kt_aside_footer'
    >
      {/* begin::User */}
      <div className='d-flex align-items-center mb-10' id='kt_header_user_menu_toggle'>
        {/* begin::Menu wrapper */}
        <div
          className='cursor-pointer symbol symbol-40px'
          data-kt-menu-trigger='click'
          data-kt-menu-overflow='false'
          data-kt-menu-placement='top-start'
          title='User profile'
        >
          {currentUser?.image != null ? (
            <img alt='Logo' src={toAbsoluteUrl(`${process.env.REACT_APP_PREVIEW_REACT_URL}/public${currentUser?.image?.url}`)} />
          ) : <div
            className={`symbol-label fs-3 bg-light-undefined text-undefined`}
          >
            {currentUser?.firstname.match(/\b(\w)/g)}
          </div>}
        </div>
        {/* end::Menu wrapper */}
        <HeaderUserMenu />
      </div>
      {/* end::User */}
    </div>
  )
}

export { AsideFooter }
