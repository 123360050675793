import { Column } from 'react-table'
import { InfoCell } from './InfoCell'
import { PlanCell } from './PlanCell'
import { ExpiryDateCell } from './ExpiryDateCell'
import { CustomHeader } from './CustomHeader'
import { AmountCell } from './AmountCell'
import { Subscription } from '../../core/_models'

const Columns: ReadonlyArray<Column<Subscription>> = [
    {
        Header: (props) => <CustomHeader tableProps={props} title='Agent' className='min-w-125px' />,
        id: 'agent',
        Cell: ({ ...props }) => <InfoCell agent={props.data[props.row.index].attributes?.agent?.data} />,
    },
    {
        Header: (props) => <CustomHeader tableProps={props} title='Plan' className='min-w-125px' />,
        id: 'plan',
        Cell: ({ ...props }) => <PlanCell plan={props.data[props.row.index].attributes} />,
    },
    {
        Header: (props) => <CustomHeader tableProps={props} title='Amount' className='min-w-125px' />,
        accessor: 'amount',
        Cell: ({ ...props }) => <AmountCell subscription={props.data[props.row.index].attributes} />,
    },
    {
        Header: (props) => <CustomHeader tableProps={props} title='Expiry Date' className='min-w-125px' />,
        accessor: 'expiry_date',
        Cell: ({ ...props }) => <ExpiryDateCell subscription={props.data[props.row.index].attributes} />,
    }
]

export { Columns }
