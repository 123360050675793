import { useEffect } from 'react'
import { Outlet, Route, Routes, Navigate } from 'react-router-dom'
import { Registration } from './components/Registration'
import { ForgotPassword } from './components/ForgotPassword'
import { ResetPassword } from './components/ResetPassword'
import { Login } from './components/Login'
import { toAbsoluteUrl } from '../../../_rashmicab/helpers'
import { useLayout } from './../../../_rashmicab/layout/core/LayoutProvider'

const AuthLayout = () => {
    const { settings } = useLayout()
    useEffect(() => {
        document.body.style.backgroundImage = 'none'
        return () => { }
    }, [])

    return (
        <>
            {
                typeof settings !== 'undefined' ?
                    typeof settings.data !== 'undefined' ?
                        <div
                            className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
                            style={typeof settings.data.attributes !== 'undefined' ?
                                typeof settings.data.attributes.background !== 'undefined' ?
                                    typeof settings.data.attributes.background.data !== 'undefined' ?
                                        typeof settings.data.attributes.background.data.attributes !== 'undefined' ?
                                            typeof settings.data.attributes.background.data.attributes.url !== 'undefined' ? {
                                                backgroundImage: `url(${process.env.REACT_APP_PREVIEW_REACT_URL}/public${settings.data.attributes.background.data.attributes.url})`,
                                                backgroundSize: 'cover',
                                            } : {
                                                backgroundImage: `url(${toAbsoluteUrl('/media/illustrations/sketchy-1/14.png')})`,
                                            } : {
                                                backgroundImage: `url(${toAbsoluteUrl('/media/illustrations/sketchy-1/14.png')})`,
                                            } : {
                                            backgroundImage: `url(${toAbsoluteUrl('/media/illustrations/sketchy-1/14.png')})`,
                                        } : {
                                        backgroundImage: `url(${toAbsoluteUrl('/media/illustrations/sketchy-1/14.png')})`,
                                    } : {
                                    backgroundImage: `url(${toAbsoluteUrl('/media/illustrations/sketchy-1/14.png')})`,
                                }}
                        >
                            {/* begin::Content */}
                            <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
                                {/* begin::Logo */}
                                {
                                    settings.data.attributes !== undefined ?
                                        settings.data.attributes.logo !== undefined ?
                                            settings.data.attributes.logo.data !== undefined ?
                                                settings.data.attributes.logo.data.attributes !== undefined ?
                                                    settings.data.attributes.logo.data.attributes.url !== undefined ?
                                                        <span className='mb-12'>
                                                            <img
                                                                alt='Logo'
                                                                src={`${process.env.REACT_APP_PREVIEW_REACT_URL}/public${settings.data.attributes.logo.data.attributes.url}`}
                                                                className='theme-dark-show h-90px'
                                                            />
                                                            <img
                                                                alt='Logo'
                                                                src={`${process.env.REACT_APP_PREVIEW_REACT_URL}/public${settings.data.attributes.logo.data.attributes.url}`}
                                                                className='theme-light-show h-90px'
                                                            ></img>
                                                        </span>
                                                        : ""
                                                    : ""
                                                : ""
                                            : ""
                                        : ""
                                }
                                {/* end::Logo */}
                                {/* begin::Wrapper */}
                                <div className='w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto'>
                                    <Outlet />
                                </div>
                                {/* end::Wrapper */}
                            </div>
                            {/* end::Content */}
                        </div>
                        : ""
                    : ""
            }
        </>
    )
}

const AuthPage = () => (
    <Routes>
        <Route element={<AuthLayout />}>
            <Route path='login' element={<Login />} />
            <Route path='registration' element={<Registration />} />
            <Route path='forgot-password' element={<ForgotPassword />} />
            <Route path='reset-password' element={<ResetPassword />} />
            <Route index element={<Login />} />
            <Route path='*' element={<Navigate to='/error/404' />} />
        </Route>
    </Routes>
)

export { AuthPage }
