import axios, {
    AxiosResponse
} from 'axios'
import {
    QueryResponse
} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const BASE = `${API_URL}/trips`
const GET_ALL = `${BASE}?populate[creator][fields][0]=firstname&populate[creator][fields][1]=lastname&populate[creator][populate][role][fields][2]=type&populate[creator][populate][role][fields][3]=name&&populate[triptype][fields][4]=title&populate[route][populate][from][fields][5]=city&populate[route][populate][from][fields][6]=state&populate[route][populate][to][fields][7]=city&populate[route][populate][to][fields][8]=state&populate[bids][fields][9]=id&fields[10]=datetime&fields[11]=return&fields[12]=status&filters[deleted]=0&filters[onlinetrip]=false`

const get = (query: string, token: string): Promise<QueryResponse> => {
    const table = query.split('&').map(pair => pair.split("="));
    const result = Object.fromEntries(table);

    var query_data = '';
    query_data = `${query_data}&pagination[pageSize]=20`;
    query_data = `${query_data}&pagination[page]=1`
    if (result.filter_tab !== undefined && result.filter_tab !== null) {
        if (result.filter_tab === "with_bid") {
            query_data = `${query_data}&filters[bids][id][$notNull]=true`;
        } else if (result.filter_tab === "without_bid") {
            query_data = `${query_data}&filters[bids][id][$notNull]=false`;
        } else if (result.filter_tab === "accepted") {
            query_data = `${query_data}&filters[status]=ongoing&filters[bid_confirm]=true`;
        } else {
            // query_data = `${query_data}&filters[bids][id][$notNull]=true`;
        }
    } else {
        // query_data = `${query_data}&filters[bids][id][$notNull]=true`;
    }
    query_data = `${query_data}&sort=id:desc`;

    return axios
        .get(`${GET_ALL}${query_data}`, {
            headers: {
                "Authorization": `Bearer  ${token}`
            }
        })
        .then((d: AxiosResponse<QueryResponse>) => {
            if (result.pagesize === "all") {
                if (typeof d.data !== "undefined") {
                    if (typeof d.data.meta !== "undefined") {
                        if (typeof d.data.meta.pagination !== "undefined") {
                            if (typeof d.data.meta.pagination.pageSize !== "undefined") {
                                d.data.meta.pagination.pageSize = "all";
                            }
                        }
                    }
                }
            }
            return d.data;
        })
}


export {
    get
}