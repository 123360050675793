const ProjectsTab = () => {
  return (
    <div className='m-0'>
      {/* begin::Toolbar */}
      <div className='d-flex mb-10'>
      </div>
      {/* end::Toolbar */}

      {/*begin::Projects*/}
      <div className='m-0'>
        {/*begin::Heading*/}
        <h1 className='text-gray-800 fw-bold mb-6 mx-5'>Quick Links</h1>
        {/*end::Heading*/}

        {/*begin::Items*/}
        <div className='mb-10'>
        </div>
        {/*end::Items*/}
      </div>
      {/*end::Projects*/}
    </div>
  )
}

export { ProjectsTab }
