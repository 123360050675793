import axios, { AxiosResponse } from 'axios'
import { Response } from '../../../../_rashmicab/helpers'
const API_URL = process.env.REACT_APP_API_URL
const URL = `${API_URL}/adminsetting?populate=*`
const URL_UPDATE = `${API_URL}/adminsetting`
const UPLOAD_IMAGE = `${API_URL}/upload`

const get = (token: string): Promise<any> => {
  return axios
    .get(`${URL}`, {
      headers: { "Authorization": `Bearer  ${token}` }
    })
    .then((response: AxiosResponse<any>) => response)
    .then((response: Response<any>) => response)
}
const updateEntry = (data: any, token: string): Promise<any | undefined> => {
  let body = { data: data };
  return axios
    .put(`${URL_UPDATE}`, body, {
      headers: { "Authorization": `Bearer  ${token}` }
    })
    .then((response: AxiosResponse<Response<any>>) => response)
    .then((response: Response<any>) => response)
}
const uploadImage = (file: any, token: string): Promise<any> => {
  let formData = new FormData();
  formData.append("files", file);
  return axios.post(`${UPLOAD_IMAGE}`, formData, {
    headers: {
      "Authorization": `Bearer  ${token}`,
      "Content-Type": "multipart/form-data",
    }
  }).then((response: AxiosResponse<Response<any>>) => response)
    .then((response: Response<any>) => response)
}
export { get, updateEntry, uploadImage }
