import { FC } from 'react'
import { Link } from 'react-router-dom'

type Props = {
    creator: any,
    trip: any
}

const UserCell: FC<Props> = ({ creator, trip }) => (
    <div className='d-flex align-items-center'>
        <div className='d-flex flex-column'>
            {
                trip.trip_id !== null ?
                    typeof trip.trip_id !== "undefined" ?
                        <Link className='text-gray-800 text-hover-primary mb-1'
                            to={`/app/trips/${trip.trip_id}`}
                        >
                            {`RC#${trip.trip_id}`}
                        </Link>
                        : ''
                    : ''
            }
            {
                creator.user_id !== null ?
                    typeof creator.user_id !== "undefined" ?
                        <Link className='text-gray-600 text-hover-primary mb-1'
                            to={`/app/agents/${creator.user_id}`}
                        >
                            {`${creator?.user_id !== null ?
                                creator?.firstname + " " + creator?.lastname
                                : ''}`}
                        </Link>
                        : ''
                    : ''
            }

        </div>
    </div>
)

export { UserCell }
