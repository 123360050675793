import { toast } from 'react-toastify'

export function message(msg, type = null, duration = null, ttheme = null) {
    const _duration = duration !== null ? duration : 2000;
    const theme = ttheme !== null ? ttheme : 'light';
    const typee = type !== null ? type : 'info';

    typee === "info" ? toast.info(msg,
        {
            autoClose: _duration,
            theme: theme
        }
    ) : typee === "error" ? toast.error(msg,
        {
            autoClose: _duration,
            theme: theme
        }
    ) : typee === "warn" ? toast.warn(msg,
        {
            autoClose: _duration,
            theme: theme
        }
    ) : typee === "success" ? toast.success(msg,
        {
            autoClose: _duration,
            theme: theme
        }
    ) : toast(msg,
        {
            autoClose: _duration,
            theme: theme
        }
    );

}
