import { Column } from 'react-table'
import { DateCell } from './DateCell'
import { UserCell } from './UserCell'
import { BidsCell } from './BidsCell'
import { InfoCell } from './InfoCell'
import { CustomHeader } from './CustomHeader'
import { StatusCell } from './StatusCell'
import { Trip } from '../../core/_models'

const Columns: ReadonlyArray<Column<Trip>> = [
  {
    Header: (props) => <CustomHeader tableProps={props} title='Route' className='min-w-125px' />,
    id: 'id',
    Cell: ({ ...props }) => <InfoCell trip={props.data[props.row.index].attributes} id={props.data[props.row.index].id} />,
  },
  {
    Header: (props) => <CustomHeader tableProps={props} title='Date' className='min-w-125px' />,
    id: 'datetime',
    Cell: ({ ...props }) => <DateCell trip={props.data[props.row.index].attributes} />,
  },
  {
    Header: (props) => <CustomHeader tableProps={props} title='Created By' className='min-w-125px' />,
    id: 'creator',
    Cell: ({ ...props }) => <UserCell trip={props.data[props.row.index].attributes} />,
  },
  {
    Header: (props) => <CustomHeader tableProps={props} title='Total Bids' className='min-w-125px' />,
    id: 'bids',
    Cell: ({ ...props }) => <BidsCell bids={props.data[props.row.index].attributes.bids} />,
  },
  {
    Header: (props) => <CustomHeader tableProps={props} title='Status' className='min-w-125px' />,
    id: 'status',
    Cell: ({ ...props }) => <StatusCell trip={props.data[props.row.index].attributes} />,
  },
]

export { Columns }
