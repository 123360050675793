import axios, {
    AxiosResponse
} from 'axios'
import {
    QueryResponse
} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const BASE = `${API_URL}/trips`
const GET_ALL = `${BASE}?fields[0]=online_trip_customer_name&fields[1]=online_trip_customer_number&fields[2]=online_trip_rate&fields[3]=online_trip_from&fields[4]=online_trip_to&populate[cabtype][fields][5]=title&fields[6]=datetime&fields[7]=return&fields[8]=status&populate[location][fields][9]=city&populate[cabtype][fields][10]=title&populate[triptype][fields][11]=title&fields[12]=expiry_date&filters[deleted]=0&fields[13]=onlinetrip_type`

const get = (query: string, token: string): Promise<QueryResponse> => {
    const table = query.split('&').map(pair => pair.split("="));
    const result = Object.fromEntries(table);

    var query_data = "&filters[onlinetrip]=true";
    query_data = `${query_data}&pagination[pageSize]=20`;
    query_data = `${query_data}&pagination[page]=1`;
    query_data = `${query_data}&sort=id:desc`;

    return axios
        .get(`${GET_ALL}${query_data}`, {
            headers: {
                "Authorization": `Bearer  ${token}`
            }
        })
        .then((d: AxiosResponse<QueryResponse>) => {
            if (result.pagesize === "all") {
                if (typeof d.data !== "undefined") {
                    if (typeof d.data.meta !== "undefined") {
                        if (typeof d.data.meta.pagination !== "undefined") {
                            if (typeof d.data.meta.pagination.pageSize !== "undefined") {
                                d.data.meta.pagination.pageSize = "all";
                            }
                        }
                    }
                }
            }
            return d.data;
        })
}

export {
    get
}