import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL

export const SETTING_URL = `${API_URL}/adminsetting?populate=*`


// Get setting 
export function setting() {
    return axios.get(SETTING_URL, {
        headers: { "Authorization": `Bearer beb1c8bc6da0a4a2e17916718bb53c0e42ddd5874ce34bfaee71e623a67dbc3c8974f61518464aab0ad272734e55aaf20378ecf7c20c4cb49ae93d1d32552cf1b3ea89b4d94f55e813aa819794417415dc5da73152b14b0a509c255fdaf4d5c67ecb715cab55418f59445584159a97df801c0ab4227970150a9d2a391f314695` }
    })
}