import { Tablist } from './Tablist'

const Header = () => {
    return (
        <div className='card-header border-0 pt-6'>
            <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bold fs-3 mb-1">Latest Trips</span>
            </h3>
            <div className='d-flex justify-content-end' data-kt-trip-table-toolbar='base'>
                <Tablist />
            </div>
        </div>
    )
}

export { Header }
