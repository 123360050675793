import { FC } from 'react'
import { Link } from 'react-router-dom'

type Props = {
    plan: any,
}

const PlanCell: FC<Props> = ({ plan }) => {
    return (
        <div className='d-flex align-items-center'>
            <div className='d-flex flex-column'>
                {
                    plan !== null ?
                        plan.plan_data !== null ?
                            <>
                                <Link className='text-gray-800 text-hover-primary mb-1' to={'/app/subscriptionplans/' + plan.plan_data.id} >
                                    {plan.plan_data.title ? plan.plan_data.title : ""}
                                </Link>
                                <span> {`${plan.plan_data.is_free_plan !== true ? plan.plan_data.validity_in_months + ' Months' : 'No Validity'}`}</span>
                            </>
                            : null
                        : null
                }
            </div>
        </div >
    )
}

export { PlanCell }
