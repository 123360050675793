/* eslint-disable jsx-a11y/anchor-is-valid */
const SubscriptionsTab = () => (
  <div className='mx-5'>
    {/*begin::Container*/}
    <div className='text-center pt-10 mb-20'>
      {/*begin::Title*/}
      <h2 className='fs-2 fw-bolder mb-7'>Reports</h2>
      {/*end::Title*/}
    </div>
    {/*end::Container*/}
  </div>
)

export { SubscriptionsTab }
