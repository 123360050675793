import {createRoot} from 'react-dom/client'
// Axios
import axios from 'axios'
import {Chart, registerables} from 'chart.js'
import {QueryClient, QueryClientProvider} from 'react-query'
// Apps
import {RashmiCabI18nProvider} from './_rashmicab/i18n/RashmiCabi18n'
/**
 * TIP: Replace this style import with rtl styles to enable rtl mode
 *
 * import './_rashmicab/assets/css/style.rtl.css'
 **/
import './_rashmicab/assets/sass/plugins.scss'
import './_rashmicab/assets/sass/style.scss'
import './_rashmicab/assets/sass/style.react.scss'

import {AppRoutes} from './app/routing/AppRoutes'
import {AuthProvider, setupAxios} from './app/modules/auth'
import {ThemeModeProvider} from './_rashmicab/partials/layout/theme-mode/ThemeModeProvider'
// import moment from "moment-timezone";

/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic Rashmi Cab mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */
/**
 * Inject Rashmi Cab interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */
setupAxios(axios)
Chart.register(...registerables)

const queryClient = new QueryClient()
const container = document.getElementById('root')
// moment.tz("Asia/Kolkata");
// console.log(moment)
if (container) {
  createRoot(container).render(
    <QueryClientProvider client={queryClient}>
      <RashmiCabI18nProvider>
        <ThemeModeProvider>
          <AuthProvider>
            <AppRoutes />
          </AuthProvider>
        </ThemeModeProvider>
      </RashmiCabI18nProvider>
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
    </QueryClientProvider>
  )
}
