import { FC } from 'react'
import { Trip } from '../../core/_models'

type Props = {
    trip: Trip,
}

const StatusCell: FC<Props> = ({ trip }) => {

    return (
        <span className={trip.status === "default" ? 'cursor-pointer badge badge-light-success me-auto' : trip.status === "ongoing" ? 'cursor-pointer badge badge-light-warning me-auto' : trip.status === "cancel" ? 'cursor-pointer badge badge-light-danger me-auto' : trip.status === "completed" ? 'cursor-pointer badge badge-light-primary  me-auto' : 'cursor-pointer badge badge-light-success me-auto'}>
            {
                trip.status === null ? '-' :
                    trip.status === "default" ?
                        "Active"
                        :
                        trip.status === "ongoing" ?
                            "Ongoing"
                            :
                            trip.status === "cancel" ?
                                "Cancel"
                                :
                                trip.status === "completed" ?
                                    "Completed"
                                    :
                                    "-"
            }
        </span>
    )

}

export { StatusCell }
