import { lazy, FC, Suspense } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { MasterLayout } from '../../_rashmicab/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import { getCSSVariableValue } from '../../_rashmicab/assets/ts/_utils'
import { WithChildren } from '../../_rashmicab/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import { useAuth } from '../../app/modules/auth'
import { Setting } from '../pages/setting/index'


const PrivateRoutes = () => {
  const StaffsPage = lazy(() => import('../modules/staff-management/StaffsPage'))
  const UserProfilePage = lazy(() => import('../modules/user-profile/UserProfilePage'))
  const App = lazy(() => import('../pages/app/App'))
  const Fe = lazy(() => import('../pages/frontend/Fe'))
  const { currentUser } = useAuth()

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        {currentUser.role.id === 1 ?
          <>
            {/* <Route path='permission' element={<Permission />} /> */}
          </>
          : ""}
          <Route path='setting' element={<Setting />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        {currentUser.role.id === 1 ?
          <Route
            path='/staffs/*'
            element={
              <SuspensedView>
                <StaffsPage />
              </SuspensedView>
            }
          />
          : ""}
        <Route
          path='/profile/*'
          element={
            <SuspensedView>
              <UserProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='app/*'
          element={
            <SuspensedView>
              <App />
            </SuspensedView>
          }
        />
        <Route
          path='frontend/*'
          element={
            <SuspensedView>
              <Fe />
            </SuspensedView>
          }
        />
        {/* <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        /> */}
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }
