import { Column } from 'react-table'
import { InfoCell } from './InfoCell'
import { CustomHeader } from './CustomHeader'
import { Agent } from '../../core/_models'

const Columns: ReadonlyArray<Column<Agent>> = [
    {
        Header: (props) => <CustomHeader tableProps={props} title='Name' className='min-w-125px' />,
        id: 'firstname',
        Cell: ({ ...props }) => <InfoCell agent={props.data[props.row.index]} />,
    },
]

export { Columns }
