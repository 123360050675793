import { Link } from 'react-router-dom'
import { KTSVG } from '../../../../helpers'
import { useAuth } from '../../../../../app/modules/auth'
let tasks: Array<{ icon: string; color: string; title: string; description: string, to: string }> = []
const TasksTab = () => {
  const { currentUser, permission } = useAuth()
  tasks = [];
  if (currentUser.role.id === 1) {
    tasks.push({
      icon: '/media/icons/duotune/customicon/staff.svg',
      color: 'success',
      title: 'Staff',
      description: 'Staff Management',
      to: '/staffs',
    }, 
    // {
    //   icon: '/media/icons/duotune/customicon/Lock-overturning.svg',
    //   color: 'warning',
    //   title: 'Permission',
    //   description: 'Staff Permission Manage',
    //   to: '/permission',
    // }, 
    // {
    //   icon: '/media/icons/duotune/customicon/Settings-2.svg',
    //   color: 'danger',
    //   title: 'Settings',
    //   description: 'Admin Panel Setting',
    //   to: '/setting',
    // }
    )
  }
  if (typeof permission !== "undefined") {

    if (permission['api::adminsetting']['controllers']['adminsetting']['find']['enabled']) {
      tasks.push({
        icon: '/media/icons/duotune/customicon/Settings-2.svg',
        color: 'danger',
        title: 'Settings',
        description: 'Admin Panel Setting',
        to: '/setting',
      })
    }
    if (permission['api::cabtype']['controllers']['cabtype']['find']['enabled']) {
      tasks.push({
        icon: '/media/icons/duotune/customicon/cab-svgrepo-com.svg',
        color: 'primary',
        title: 'Cab Types',
        description: 'Cab Types for app',
        to: '/app/cabtypes',
      })
    }
    if (permission['api::triptype']['controllers']['triptype']['find']['enabled']) {
      tasks.push({
        icon: '/media/icons/duotune/customicon/trip.svg',
        color: 'info',
        title: 'Trip Types',
        description: 'Trip Types for app',
        to: '/app/triptypes',
      })
    }
    if (permission['api::location']['controllers']['location']['find']['enabled']) {
      tasks.push({
        icon: '/media/icons/duotune/customicon/Direction2.svg',
        color: 'success',
        title: 'Cities',
        description: 'Manage cities for Routes',
        to: '/app/locations',
      })
    }
    if (permission['api::route']['controllers']['route']['find']['enabled']) {
      tasks.push({
        icon: '/media/icons/duotune/customicon/Route.svg',
        color: 'warning',
        title: 'Trip Routes',
        description: 'Manage Routes for Trips',
        to: '/app/triproutes',
      })
    }
    if (permission['api::helpdesk']['controllers']['helpdesk']['find']['enabled']) {
      tasks.push({
        icon: '/media/icons/duotune/customicon/Question-circle.svg',
        color: 'danger',
        title: 'Help Desks',
        description: 'Manage Help Desk for app',
        to: '/app/helpdesks',
      })
    }
    if (permission['api::reason']['controllers']['reason']['find']['enabled']) {
      tasks.push({
        icon: '/media/icons/duotune/customicon/reason.svg',
        color: 'primary',
        title: 'Reasons',
        description: 'Manage Reasons for app',
        to: '/app/reasons',
      })
    }
    if (permission['api::app-setting']['controllers']['app-setting']['find']['enabled'] || permission['api::appslide']['controllers']['appslide']['find']['enabled']) {
      tasks.push({
        icon: '/media/icons/duotune/customicon/Settings-1.svg',
        color: 'info',
        title: 'App Setting',
        description: 'Manage App Setting',
        to: '/app/settings',
      })
    }
    if (permission['api::subscription-plan']['controllers']['subscription-plan']['find']['enabled']) {
      tasks.push({
        icon: '/media/icons/duotune/customicon/subscription.svg',
        color: 'success',
        title: 'Subscription Plan',
        description: 'Manage Subscription Plan for app',
        to: '/app/subscriptionplans',
      })
    }
  }
  return (

    <div className='mx-5'>
      {/*begin::Header*/}
      <h3 className='fw-bolder text-dark mb-10 mx-0'>Settings & Configs</h3>
      {/*end::Header*/}

      {/*begin::Body*/}
      <div className='mb-12'>
        {tasks.map((t, jj) => (
          <div className='d-flex align-items-center mb-7' key={t.title + jj + "tabmenu"}>
            {/*begin::Symbol*/}
            <div className='symbol symbol-50px me-5'>
              <span className={`symbol-label bg-light-${t.color}`}>
                <KTSVG path={t.icon} className={`svg-icon-2x svg-icon-${t.color}`} />
              </span>
            </div>
            {/*end::Symbol*/}

            {/*begin::Text*/}
            <div className='d-flex flex-column'>
              <Link
                to={t.to}
                className='text-gray-800 text-hover-primary fs-6 fw-bold'
              >
                {t.title}
              </Link>

              <span className='text-muted fw-bold'>{t.description}</span>
            </div>
            {/*end::Text*/}
          </div>
        ))}
      </div>
      {/*end::Body*/}
    </div>
  )
}

export { TasksTab }
