import { FC } from 'react'

type Props = {
    expiredagent: any,
}

const ExpiryDateCell: FC<Props> = ({ expiredagent }) => {
    return (
        <span>
            {
                expiredagent !== null ?
                    expiredagent.expiry_date !== null ?
                        expiredagent.expiry_date ? new Intl.DateTimeFormat('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'  }).format(new Date(expiredagent.expiry_date)) : ""
                        : null
                    : null
            }
        </span>
    )
}
export { ExpiryDateCell }
