/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from 'react'
import { MenuComponent, DrawerComponent } from '../../../../../../_rashmicab/assets/ts/components'
import { KTSVG } from '../../../../../../_rashmicab/helpers'
import { DrawerMessenger } from './DrawerMessenger'

type Props = {
    complain: any,
    creator: any,
}

const ActionsCell: FC<Props> = ({ complain, creator }) => {
    const [showComplaint, setShowComplaint] = useState(false);

    useEffect(() => {
        MenuComponent.reinitialization()
        DrawerComponent.bootstrap()
        DrawerComponent.reinitialization()
    }, [showComplaint])

    const openComplaint = () => {
        setShowComplaint(true);
    };

    return (
        <>
            {/* begin::Menu wrapper */}
            <div
                className='btn btn-icon btn-light w-40px h-40px pulse pulse-white'
                id='kt_drawer_chat_toggle' data-kt-drawer-show="true" onClick={openComplaint}
            >
                <KTSVG path='/media/icons/duotune/communication/com012.svg' className='svg-icon-2' />
                <span className='pulse-ring' />
            </div>
            {/* end::Menu wrapper */}
            {/* begin::Drawers */}
            {showComplaint ? <DrawerMessenger complain={complain} setShowComplaint={setShowComplaint} creator={creator} /> : null}
            {/* end:: Drawers */}
        </>
    )
}

export { ActionsCell }
