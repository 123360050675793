import { FC } from 'react'

type Props = {
    subscription: any,
}

const ExpiryDateCell: FC<Props> = ({ subscription }) => {
    return (
        <span>
            {
                subscription !== null ?
                    subscription.expiry_date !== null ?
                        subscription.expiry_date ? new Intl.DateTimeFormat('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'  }).format(new Date(subscription.expiry_date)) : ""
                        : null
                    : null
            }
        </span>
    )
}
export { ExpiryDateCell }
