/* eslint-disable react-hooks/exhaustive-deps */
import { FC } from 'react'
import { Onlinetrip } from '../../core/_models'

type Props = {
    trip: Onlinetrip,
}

const ExpiryDateCell: FC<Props> = ({ trip }) => {
    return (
        <>

            {
                trip.expiry_date !== null ?
                    new Date(trip.expiry_date) >= new Date() ?
                        <span className="cursor-pointer badge badge-light-success me-auto">
                            {`${trip.expiry_date ?
                                new Intl.DateTimeFormat('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'  }).format(new Date(trip.expiry_date))
                                : ''}`}
                        </span>
                        : <span className="cursor-pointer badge badge-light-danger me-auto">
                            {`${trip.expiry_date ?
                                new Intl.DateTimeFormat('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'  }).format(new Date(trip.expiry_date))
                                : ''}`}
                        </span>
                    : ""
            }
        </>
    )

}

export { ExpiryDateCell }
