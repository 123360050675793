import { FC } from 'react'
import { Customer } from '../../core/_models'

type Props = {
    customer: Customer
}

const StatusCell: FC<Props> = ({ customer }) => {
    return (
        <>
            {customer.status ?
                <span className={customer.status === "default" ? 'cursor-pointer badge badge-light-success me-auto' : customer.status === "inactive" ? 'cursor-pointer badge badge-light-danger me-auto' : customer.status === "approve" ? 'cursor-pointer badge badge-light-primary  me-auto' : 'cursor-pointer badge badge-light-success me-auto'}>{customer.status === null ? '-' :
                    customer.status === "default" ? "Active" : customer.status === "approve" ? "Approve" : customer.status === "inactive" ? "Inactive" : "-"
                }</span>
                : "-"}
        </>
    )

}

export { StatusCell }
