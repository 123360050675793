/* eslint-disable react/jsx-no-target-blank */
import { AsideMenuItem } from './AsideMenuItem'
import { useAuth } from '../../../../app/modules/auth'

export function AsideMenuMain() {
  const { permission } = useAuth()
  return (
    <>
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Apps</span>
        </div>
      </div>
      {typeof permission !== "undefined" ?
        <>
          {permission['plugin::users-permissions']['controllers']['user']['find']['enabled'] ?
            <>
              <AsideMenuItem
                to='/app/agents'
                title='Agents'
                fontIcon='bi-archive'
                icon='/media/icons/duotune/communication/com006.svg'
              ></AsideMenuItem>
              <AsideMenuItem
                to='/app/customers'
                title='Customers'
                fontIcon='bi-archive'
                icon='/media/icons/duotune/communication/com006.svg'
              ></AsideMenuItem>
            </>
            : ""}
          {permission['api::driver']['controllers']['driver']['find']['enabled'] ?
            <AsideMenuItem
              to='/app/drivers'
              title='Drivers'
              fontIcon='bi-archive'
              icon='/media/icons/duotune/technology/Drivers.svg'
            ></AsideMenuItem>
            : ""}
          {permission['api::cab']['controllers']['cab']['find']['enabled'] ?
            <AsideMenuItem
              to='/app/cabs'
              title='Cabs'
              fontIcon='bi-archive'
              icon='/media/icons/duotune/customicon/cab-svgrepo-com.svg'
            ></AsideMenuItem>
            : ""}

          {permission['api::trip']['controllers']['trip']['find']['enabled'] ?
            <AsideMenuItem
              to='/app/trips'
              title='Trips'
              fontIcon='bi-archive'
              icon='/media/icons/duotune/customicon/trip.svg'
            ></AsideMenuItem>
            : ""}
          {/* {permission['api::bid']['controllers']['bid']['find']['enabled'] ?
          <AsideMenuItem
            to='/app/bids'
            title='Bids'
            fontIcon='bi-archive'
            icon='/media/icons/duotune/general/gen025.svg'
          ></AsideMenuItem>
          : ""} */}
          {permission['api::trip']['controllers']['trip']['find']['enabled'] ?
            <AsideMenuItem
              to='/app/onlinetrips'
              title='Online Trips'
              fontIcon='bi-archive'
              icon='/media/icons/duotune/customicon/online-trip.svg'
            ></AsideMenuItem>
            : ""}
          {permission['api::trip-complain']['controllers']['trip-complain']['find']['enabled'] ?
            <AsideMenuItem
              to='/app/complaints'
              title='Complaints'
              fontIcon='bi-archive'
              icon='/media/icons/duotune/communication/com012.svg'
            ></AsideMenuItem>
            : ""}
          {permission['api::invoice']['controllers']['invoice']['find']['enabled'] ?
            <AsideMenuItem
              to='/app/invoices'
              title='Invoices'
              fontIcon='bi-archive'
              icon='/media/icons/duotune/customicon/clipboard-list.svg'
            ></AsideMenuItem>
            : ""}

        </>
        : ''}

<div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>FrontEnd</span>
        </div>
      </div>
      {typeof permission !== "undefined" ?
        <>
         {permission['api::fecontactform']['controllers']['fecontactform']['find']['enabled'] ?
            <AsideMenuItem
              to='/frontend/contacts'
              title='Contact Form'
              fontIcon='bi-archive'
              icon='/media/icons/duotune/communication/com012.svg'
            ></AsideMenuItem>
            : ""}
         </>
        : ''}
    </>
  )
}
