import { FC } from 'react'
import { Driver } from '../../core/_models'

type Props = {
    driver: Driver,
}

const StatusCell: FC<Props> = ({ driver }) => {
    return (
        <span className={driver.approve === true ? 'cursor-pointer badge badge-light-primary me-auto' : 'cursor-pointer badge badge-light-danger me-auto'}>{driver.approve === true ? "Approve" : "Pending"}</span>
    )
}
export { StatusCell }
