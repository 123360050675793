/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useState, useEffect, ChangeEvent } from 'react'
import { Link } from 'react-router-dom'
import clsx from 'clsx'
import { toAbsoluteUrl } from '../../../../../../_rashmicab/helpers'
import { createEntry, getById, uploadImage } from '../../core/_requests'
import { useAuth } from '../../../../../modules/auth'
import { message } from '../../../../../services'

type Props = {
    isDrawer?: boolean,
    currentcomplain: any,
    complaintid: Number,
    complaints?: any,
    setCurrentcomplaint: any
}

type Image = {
    url: any,
    id: Number,
}

const ChatInner: FC<Props> = ({ isDrawer = false, currentcomplain, complaintid, complaints, setCurrentcomplaint }) => {
    const [complaint, setComplaint] = useState<string>('')
    const [attachment, setattachment] = useState<Image[]>([])
    const [attachmentId, setattachmentId] = useState<string>('')
    const { currentUser, permission } = useAuth();
    const { setLightbox } = useAuth()
    const { auth } = useAuth();
    const blankImg = toAbsoluteUrl('/media/svg/avatars/blank.svg')
    const element = document.querySelector('#kt_drawer_chat_messenger_body .scroll-y');

    const sendComplaint = () => {
        const newComplaint = Object();
        if (complaint.trim() !== "") {
            newComplaint.message = complaint;
        }
        newComplaint.user = currentUser.id;
        newComplaint.complain = Number(complaintid);
        if (attachmentId !== "") {
            var _galleryid = attachmentId.split(',')
            newComplaint.attachment = _galleryid.map((id) => Number(id));
        }
        else {
            newComplaint.attachment = null;
        }

        if (permission['api::trip-complain']['controllers']['trip-complain']['create']['enabled'] === false) {
            setComplaint('')
            setattachment([])
            setattachmentId('')
            message("No Permission to Add new message!", 'error')
        } else {
            createEntry(newComplaint).then((response) => {
                if (auth && auth.jwt) {
                    getById(Number(complaintid), auth.jwt).then((data) => {
                        setCurrentcomplaint(data.data.data);
                        setComplaint('')
                        setattachment([])
                        setattachmentId('')
                        element.scrollTop = element.scrollHeight;
                    });
                }
            }).catch(({ data }) => {
                message(data.data.error.message, 'error')
            })
        }
    }

    useEffect(() => {
        if (Object.keys(complaints).length > 0) {
            const element = document.querySelector('#kt_drawer_chat_messenger_body .scrolling');
            if (element !== null) {
                element.scrollTop = element.scrollHeight;
                element.classList.remove("scrolling");
            }
        }
    }, [complaints])

    const onEnterPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.keyCode === 13 && e.shiftKey === false) {
            if (complaint.trim() !== "" && attachmentId !== "") {
                e.preventDefault()
                sendComplaint()
                element.scrollTop = element.scrollHeight;
            }
        }
    }
    const handleFileChange = (e: ChangeEvent<HTMLInputElement>, field: any = null, index: any = null) => {
        if (e.target.files.length) {
            uploadImage(e.target.files).then((response) => {
                const id = response.data.map(({ id }) => id).join(', ');
                if (attachmentId !== "") {
                    setattachment([...attachment, ...response.data])
                    setattachmentId(attachmentId + ', ' + id)
                }
                else {
                    setattachment(response.data)
                    setattachmentId(id)
                }
                message("image uploaded!", 'success')
            }).catch(({ response }) => {
                message(response.data.error.message, 'error')
            });
        }
    }

    const removeFile = (index: any = null) => {
        const gallery_ = attachment.filter((_, key) => {
            return key !== index;
        })
        const galleryid_ = attachmentId.split(',');
        const galleryid__ = galleryid_.filter((_, key) => {
            return key !== index;
        })

        setattachment(gallery_)
        setattachmentId(galleryid__.toString())
    }

    const lightBoxImage = (image: any, index: any) => {
        var toggler = true;
        var images = '' || [];

        if (typeof image !== 'string') {
            images = image.map((_image) => {
                var lightboxData1 = Object();
                if (_image.attributes !== undefined) {
                    lightboxData1.src = `${process.env.REACT_APP_PREVIEW_REACT_URL}/public${_image.attributes.url}`
                } else {
                    lightboxData1.src = `${process.env.REACT_APP_PREVIEW_REACT_URL}/public${_image.url}`
                }
                return lightboxData1;
            })
        } else {
            var lightboxData1 = Object();
            lightboxData1.src = image;
            images = [lightboxData1]
        }
        var lightboxData = Object();
        lightboxData.toggler = toggler;
        lightboxData.sources = images;
        lightboxData.index = index;
        setLightbox(lightboxData);
    }
    return (
        <>
            <div
                className='card-body'
                id={isDrawer ? 'kt_drawer_chat_messenger_body' : 'kt_chat_messenger_body'}
            >
                <div
                    className={clsx('scrolling scroll-y me-n5 pe-5', { 'h-300px h-lg-auto': !isDrawer })}
                    data-kt-element='messages'
                    data-kt-scroll='true'
                    data-kt-scroll-activate='{default: false, lg: true}'
                    data-kt-scroll-max-height='auto'
                    data-kt-scroll-dependencies={
                        isDrawer
                            ? '#kt_drawer_chat_messenger_header, #kt_drawer_chat_messenger_footer'
                            : '#kt_header, #kt_app_header, #kt_app_toolbar, #kt_toolbar, #kt_footer, #kt_app_footer, #kt_chat_messenger_header, #kt_chat_messenger_footer'
                    }
                    data-kt-scroll-wrappers={
                        isDrawer ? '#kt_drawer_chat_messenger_body' : '#kt_content, #kt_app_content, #kt_chat_messenger_body'
                    }
                    data-kt-scroll-offset={isDrawer ? '0px' : '-2px'}
                >
                    {
                        Object.keys(complaints).length !== 0 ?
                            Object.keys(complaints).map((message, index) => {
                                const state = (complaints[message].attributes.user.data.attributes.role.data.id === 1 || complaints[message].attributes.user.data.attributes.role.data.id === 3) ? 'primary' : 'info'
                                const templateAttr = {}
                                if (complaints[message].attributes.template) {
                                    Object.defineProperty(templateAttr, 'data-kt-element', {
                                        value: `template-${complaints[message].attributes.type}`,
                                    })
                                }
                                const contentClass = `${isDrawer ? '' : 'd-flex'} justify-content-${(complaints[message].attributes.user.data.attributes.role.data.id === 1 || complaints[message].attributes.user.data.attributes.role.data.id === 3) ? 'end' : 'start'
                                    } mb-10`
                                return (
                                    <div
                                        key={`message${index}`}
                                        className={clsx('d-flex', contentClass, 'mb-10', { 'd-none': complaints[message].attributes.template })}
                                        {...templateAttr}
                                    >
                                        <div
                                            className={clsx(
                                                'd-flex flex-column align-items',
                                                `align-items-${(complaints[message].attributes.user.data.attributes.role.data.id === 1 || complaints[message].attributes.user.data.attributes.role.data.id === 3) ? 'end' : 'start'}`
                                            )}
                                        >
                                            <div className='d-flex align-items-center mb-2'>
                                                {(complaints[message].attributes.user.data.attributes.role.data.id === 1 || complaints[message].attributes.user.data.attributes.role.data.id === 3) ? (
                                                    <>
                                                        <div className='me-3 text-end d-flex flex-column'>
                                                            {
                                                                currentUser.id === complaints[message].attributes.user.data.id ?
                                                                    <Link to='/profile'
                                                                        className='fs-5 fw-bolder text-gray-900 text-hover-primary ms-1'
                                                                    >
                                                                        You
                                                                    </Link>
                                                                    : <span
                                                                        className='fs-6 fw-bolder text-gray-900 text-hover-primary ms-1'
                                                                    >
                                                                        {complaints[message].attributes.user.data.attributes.firstname + ' ' + complaints[message].attributes.user.data.attributes.lastname}
                                                                    </span>
                                                            }
                                                            <span className='text-muted fs-8 mb-1'>{complaints[message].attributes.createdAt ?
                                                                new Intl.DateTimeFormat('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(new Date(complaints[message].attributes.createdAt))
                                                                : ''}</span>
                                                        </div>
                                                        <div className='symbol symbol-35px symbol-circle '>
                                                            {
                                                                complaints[message].attributes.user.data.attributes.image.data !== null ?
                                                                    <img alt='Pic' src={toAbsoluteUrl(`${process.env.REACT_APP_PREVIEW_REACT_URL}/public${complaints[message].attributes.user.data.attributes.image.data.attributes.url}`)} />
                                                                    : <div
                                                                        className={clsx(
                                                                            'symbol-label fs-3',
                                                                            `bg-light-undefined`,
                                                                            `text-undefined`
                                                                        )}
                                                                    >
                                                                        {complaints[message].attributes.user?.data?.attributes?.firstname.charAt(0).toUpperCase()}
                                                                    </div>
                                                            }
                                                        </div>
                                                    </>
                                                ) : (
                                                    <>
                                                        <div className='symbol symbol-35px symbol-circle '>
                                                            {
                                                                complaints[message].attributes.user.data.attributes.image.data !== null ?
                                                                    <img alt='Pic' src={toAbsoluteUrl(`${process.env.REACT_APP_PREVIEW_REACT_URL}/public${complaints[message].attributes.user.data.attributes.image.data.attributes.url}`)} />
                                                                    : <div
                                                                        className={clsx(
                                                                            'symbol-label fs-3',
                                                                            `bg-light-undefined`,
                                                                            `text-undefined`
                                                                        )}
                                                                    >
                                                                        {complaints[message].attributes.user?.data?.attributes?.firstname.charAt(0).toUpperCase()}
                                                                    </div>
                                                            }
                                                        </div>
                                                        <div className='ms-3 text-start d-flex flex-column'>
                                                            <Link
                                                                to={`${complaints[message].attributes.user.data !== null ?
                                                                    complaints[message].attributes.user.data.attributes.role.data.id === 4 ?
                                                                        '/app/agents/' + complaints[message].attributes.user.data.id
                                                                        : complaints[message].attributes.user.data.attributes.role.data.id === 5 ?
                                                                            '/app/customers/' + complaints[message].attributes.user.data.id
                                                                            : '#'
                                                                    : '#'}`}
                                                                className='fs-6 fw-bolder text-gray-900 text-hover-primary me-1 text-capitalize'
                                                            >
                                                                {complaints[message].attributes.user.data.attributes.firstname + ' ' + complaints[message].attributes.user.data.attributes.lastname}
                                                            </Link>
                                                            <span className='text-muted fs-8 mb-1'>{complaints[message].attributes.createdAt ?
                                                                new Intl.DateTimeFormat('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(new Date(complaints[message].attributes.createdAt))
                                                                : ''}</span>
                                                        </div>
                                                    </>

                                                )}
                                            </div>
                                            {
                                                typeof complaints[message].attributes.attachment !== "undefined" && complaints[message].attributes.attachment.data !== null ?
                                                    complaints[message].attributes.attachment.data.length > 0 ?
                                                        <div className="row">
                                                            {
                                                                complaints[message].attributes.attachment.data.map((attachmennt, index) => (
                                                                    index < 2 ?
                                                                        <div className='col-auto me-0 mb-3' data-total={complaints[message].attributes.attachment.data.length > 2 ? true : false} data-index={index} key={index}>
                                                                            <div className={(attachmennt !== null) ? attachmennt.attributes.url === '' ? 'image-input image-input-outline image-input-empty'
                                                                                : 'image-input image-input-outline cursor-pointer' : 'image-input image-input-outline image-input-empty cursor-pointer'} data-kt-image-input='true'
                                                                                style={{ backgroundImage: `url('${blankImg}')` }}>
                                                                                {((typeof attachmennt !== "undefined") && attachmennt !== null) ?
                                                                                    <div className='image-input-wrapper w-125px h-125px object-fit-contain'
                                                                                        style={{ backgroundImage: `url('${process.env.REACT_APP_PREVIEW_REACT_URL}/public${attachmennt.attributes.url}')` }}
                                                                                        onClick={(event) => lightBoxImage(complaints[message].attributes.attachment.data, index)}></div>
                                                                                    : <div className='image-input-wrapper w-125px h-125px object-fit-contain'
                                                                                        style={{ backgroundImage: `url('${blankImg}')` }}
                                                                                    ></div>}
                                                                                <span className="total" onClick={(event) => lightBoxImage(complaints[message].attributes.attachment.data, index)}>
                                                                                    <span className="pluse">+ {complaints[message].attributes.attachment.data.length - 2} </span>
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                        : ""
                                                                ))
                                                            }
                                                        </div>
                                                        : ""
                                                    : ""
                                            }
                                            {
                                                complaints[message].attributes.message !== null ?
                                                    <div
                                                        className={clsx(
                                                            'p-5 rounded',
                                                            `bg-light-${state}`,
                                                            'text-dark fw-bold mw-lg-400px',
                                                            `text-${(complaints[message].attributes.user.data.attributes.role.data.id === 1 || complaints[message].attributes.user.data.attributes.role.data.id === 3) ? 'end' : 'start'}`
                                                        )}
                                                        data-kt-element='message-text'
                                                        dangerouslySetInnerHTML={{ __html: complaints[message].attributes.message }}
                                                    ></div>
                                                    : ""
                                            }
                                        </div>
                                    </div>
                                )
                            })
                            : ""
                    }
                </div>
            </div>
            {currentcomplain.complian_status !== 'closed' ?
                <div
                    className='card-footer pt-4'
                    id={isDrawer ? 'kt_drawer_chat_messenger_footer' : 'kt_chat_messenger_footer'}
                >
                    <div className="new_attachemnt">
                        {
                            typeof attachment !== 'undefined' && attachment !== null ?
                                attachment.length > 0 ?
                                    <div className="row">
                                        {
                                            attachment.map((attachmennt, index) => (
                                                <div className='col-auto mt-3 me-0 mb-3' key={index}>
                                                    <div className={(attachmennt !== null) ? attachmennt.url === '' ? 'image-input image-input-outline image-input-empty'
                                                        : 'image-input image-input-outline cursor-pointer' : 'image-input image-input-outline image-input-empty cursor-pointer'} data-kt-image-input='true'
                                                        style={{ backgroundImage: `url('${blankImg}')` }}>
                                                        {((typeof attachmennt !== "undefined") && attachmennt !== null) ?
                                                            <div className='image-input-wrapper w-125px h-125px object-fit-contain'
                                                                style={{ backgroundImage: `url('${process.env.REACT_APP_PREVIEW_REACT_URL}/public${attachmennt.url}')` }}
                                                                onClick={(event) => lightBoxImage(attachment, index)}></div>
                                                            : <div className='image-input-wrapper w-125px h-125px object-fit-contain'
                                                                style={{ backgroundImage: `url('${blankImg}')` }}
                                                            ></div>}
                                                        <span
                                                            className='remove_image btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                                                            data-kt-image-input-action={((typeof attachmennt.url !== "undefined") && attachmennt.url !== null) ? "" : "cancel"} data-bs-toggle='tooltip'
                                                            title='Cancel image' onClick={(event) => removeFile(index)}
                                                        >
                                                            <i className='bi bi-x fs-2'></i>
                                                        </span>
                                                    </div>
                                                </div>

                                            ))
                                        }
                                    </div>
                                    : ""
                                : ""
                        }
                    </div>
                    <textarea
                        className='form-control form-control-flush mb-3'
                        rows={1}
                        data-kt-element='input'
                        placeholder='Type a message'
                        value={complaint}
                        onChange={(e) => setComplaint(e.target.value)}
                        onKeyDown={onEnterPress}
                    ></textarea>
                    <div className='d-flex flex-stack'>
                        <div className='d-flex align-items-center image-input me-2' >
                            <label
                                className='btn btn-sm btn-icon btn-active-light-primary me-1'
                                data-bs-toggle='tooltip'
                                title='Coming soon'
                                data-kt-image-input-action="change"
                            >
                                <i className='bi bi-upload fs-3'></i>
                                <input type='file' name='numberplate' accept='.png, .jpg, .jpeg' onChange={(event) =>
                                    handleFileChange(event, 'numberplate')} multiple />
                                <input type='hidden' name='image_remove' />
                            </label>
                        </div>
                        <button
                            className='btn btn-primary ms-auto'
                            type='button'
                            data-kt-element='send'
                            onClick={sendComplaint}
                            disabled={(complaint.trim() === "" && attachmentId === "")}
                        >
                            Send
                        </button>
                    </div>
                </div>
                : null}
        </>
    )
}

export { ChatInner }
