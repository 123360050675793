import axios, { AxiosResponse } from 'axios'
import { QueryResponse } from './_models'

const API_URL = process.env.REACT_APP_API_URL
const GET_USERS_URL = `${API_URL}/users?fields[0]=firstname&fields[1]=lastname&fields[2]=username&fields[3]=email&fields[4]=status&populate[image][fields][6]=url&filters[role]=4`

const get = (query: string, token: string): Promise<QueryResponse> => {
    const table = query.split('&').map(pair => pair.split("="));
    const result = Object.fromEntries(table);

    var query_data = "";
    query_data = `${query_data}&limit=19`;
    query_data = `${query_data}&start=0`;
    query_data = `${query_data}&sort=id:desc`;
    query_data = `${query_data}&filters[status][$ne]=deleted&filters[status][$eq]=inactive`;

    return axios
        .get(`${GET_USERS_URL}${query_data}`, {
            headers: { "Authorization": `Bearer  ${token}` }
        })
        .then((d: AxiosResponse<QueryResponse>) => {
            if (result.pagesize === "all") {
                if (typeof d.data !== "undefined") {
                    if (typeof d.data.meta !== "undefined") {
                        if (typeof d.data.meta.pagination !== "undefined") {
                            if (typeof d.data.meta.pagination.pageSize !== "undefined") {
                                d.data.meta.pagination.pageSize = "all";
                            }
                        }
                    }
                }
            }
            return d.data;
        }
        )
}

export { get }
