import { FC, useState, createContext, useContext } from 'react'
import {
    QueryState,
    QueryRequestContextProps,
    initialQueryRequest,
    WithChildren,
} from '../../../../../_rashmicab/helpers'

const QueryRequestContext = createContext<QueryRequestContextProps>(initialQueryRequest)

const QueryRequestProvider: FC<WithChildren> = ({ children }) => {
    const [state, setState] = useState<QueryState>(initialQueryRequest.state)

    const updateState = (updates: Partial<QueryState>) => {
        if (updates.total > 0) {
            const updatedState = { ...state, ...updates } as QueryState
            setState(updatedState)
        }
    }

    return (
        <QueryRequestContext.Provider value={{ state, updateState }}>
            {children}
        </QueryRequestContext.Provider>
    )
}

const useQueryRequest = () => useContext(QueryRequestContext)
export { QueryRequestProvider, useQueryRequest }
