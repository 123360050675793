import { FC } from 'react'
import { Cab } from '../../core/_models'

type Props = {
    cab: Cab,
}

const LicenseCell: FC<Props> = ({ cab }) => (
    <div className='d-flex align-items-center'>
        <div className='d-flex flex-column'>
            <span>{cab.number === null ? '-' : cab.number}</span>
        </div>
    </div>
)

export { LicenseCell }
