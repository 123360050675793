/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import * as Yup from 'yup'
import { Link, useNavigate, Navigate, useSearchParams } from 'react-router-dom'
import { useFormik } from 'formik'
import { requestresetPassword } from '../core/_requests'
import { message } from './../../../services'

const initialValues = {
    code: '',
    newPassword: '',
    passwordConfirmation: '',
}

const resetPasswordSchema = Yup.object().shape({
    newPassword: Yup.string().trim().strict(true)
        .min(3, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('Password is required'),
    passwordConfirmation: Yup.string().trim().strict(true)
        .min(3, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('Password is required')
        .oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),
})

export function ResetPassword() {
    const [loading, setLoading] = useState(false)
    const [token, setToken] = useState<any | null>('');
    const [params] = useSearchParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (params.get('code')) {
            setToken(params.get('code'))
        }
    }, [])

    const formik = useFormik({
        initialValues,
        validationSchema: resetPasswordSchema,
        onSubmit: async (values, { setSubmitting }) => {
            setLoading(true)
            await requestresetPassword(token, values.newPassword, values.passwordConfirmation)
                .then((response) => {
                    setLoading(false)
                    message("Password Resetted!!", 'success')
                    navigate("/", { replace: true });
                })
                .catch((response) => {
                    setLoading(false)
                    setSubmitting(false)
                    message(response.response.status === 400 ? response.response.data.error.message : response.response.data.message[0].messages[0].message, 'error')
                    navigate("/", { replace: true });
                })
        },
    })

    return (
        <>
            {
                token === null ?
                    <Navigate to="/" />
                    :
                    <form
                        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
                        noValidate
                        id='kt_login_password_reset_form'
                        onSubmit={formik.handleSubmit}
                    >


                        <div className='text-center mb-10'>
                            {/* begin::Title */}
                            <h1 className='text-dark mb-3'>Reset Password</h1>
                            {/* end::Title */}

                            {/* begin::Link */}
                            <div className='text-gray-400 fw-bold fs-4'>Enter your New Password and Confirm New Password.</div>
                            {/* end::Link */}
                        </div>



                        {/* begin::Form group */}
                        <input type="hidden" {...formik.getFieldProps('code')} />
                        <div className='fv-row mb-10'>
                            <label htmlFor='newpassword' className='form-label fw-bolder text-gray-900 fs-6'>
                                New Password
                            </label>
                            <input
                                type='password'
                                className='form-control form-control-lg form-control-solid '
                                id='newpassword'
                                {...formik.getFieldProps('newPassword')}
                            />
                            {formik.touched.newPassword && formik.errors.newPassword && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>{formik.errors.newPassword}</div>
                                </div>
                            )}
                        </div>

                        <div className='fv-row mb-10'>
                            <label htmlFor='confirmpassword' className='form-label fw-bolder text-gray-900 fs-6'>
                                Confirm New Password
                            </label>
                            <input
                                type='password'
                                className='form-control form-control-lg form-control-solid '
                                id='confirmpassword'
                                {...formik.getFieldProps('passwordConfirmation')}
                            />
                            {formik.touched.passwordConfirmation && formik.errors.passwordConfirmation && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>{formik.errors.passwordConfirmation}</div>
                                </div>
                            )}
                        </div>
                        {/* end::Form group */}

                        {/* begin::Form group */}
                        <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
                            <button
                                type='submit'
                                id='kt_password_reset_submit'
                                className='btn btn-lg btn-primary fw-bolder me-4' disabled={formik.isSubmitting || !formik.isValid}
                            >
                                {!loading && <span className='indicator-label'>Submit</span>}
                                {loading && (
                                    <span className='indicator-progress' style={{ display: 'block' }}>
                                        Please wait...
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    </span>
                                )}
                            </button>
                            <Link to='/auth/login'>
                                <button
                                    type='button'
                                    id='kt_login_password_reset_form_cancel_button'
                                    className='btn btn-lg btn-light-primary fw-bolder'
                                    disabled={formik.isSubmitting || !formik.isValid}
                                >
                                    Login
                                </button>
                            </Link>{' '}
                        </div>
                        {/* end::Form group */}
                    </form >
            }
        </>
    )
}
