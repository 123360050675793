const Header = () => {
    return (
        <div className='card-header border-0 pt-6'>
            <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bold fs-3 mb-1">Latest Inactive Agents</span>
            </h3>
        </div>
    )
}

export { Header }
