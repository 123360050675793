import clsx from 'clsx'
import { FC } from 'react'
import { Link } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../../../../_rashmicab/helpers'
import { Customer } from '../../core/_models'

type Props = {
    customer: Customer
}

const InfoCell: FC<Props> = ({ customer }) => (
    <div className='d-flex align-items-center'>
        <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
            <Link className='' to={'/app/customers/' + customer.id} >
                {customer.image !== null ? (
                    <div className='symbol-label'>
                        <img src={toAbsoluteUrl(`${process.env.REACT_APP_PREVIEW_REACT_URL}/public${customer.image.url}`)} alt={customer.username} className='w-100' />
                    </div>
                ) : (
                    <div
                        className={clsx(
                            'symbol-label fs-3',
                            `bg-light-${customer.initials?.state}`,
                            `text-${customer.initials?.state}`
                        )}
                    >
                        {customer?.firstname.charAt(0).toUpperCase()}
                    </div>
                )}
            </Link>
        </div>
        <div className='d-flex flex-column'>
            <Link className='text-gray-800 text-hover-primary mb-1' to={'/app/customers/' + customer.id} >
                {
                    customer.prefix !== undefined && customer.prefix !== null ?
                        customer.prefix + " "
                        : ""
                }
                {`${customer.firstname ? customer.firstname : ''} ${customer.lastname ? customer.lastname : ""}`}
            </Link>
            <span>{customer.email}</span>
        </div>
    </div>
)

export { InfoCell }
