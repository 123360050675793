import { FC } from 'react'

type Props = {
    city: any,
}

const CityCell: FC<Props> = ({ city }) => (
    <div className='d-flex align-items-center'>
        <div className='d-flex flex-column'>
            {
                typeof city !== 'undefined' ?
                    <span>{typeof city.data !== 'undefined' && city.data !== null ?
                        city.data?.attributes?.city
                        : "No City"}</span>
                    : "-"
            }
        </div>
    </div>
)

export { CityCell }
