import { FC } from 'react'
import { Link } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../../_rashmicab/helpers'

const Error500: FC = () => {
  return (
    <div className='d-flex flex-column flex-root'>
      {/*begin::Authentication - Error 500 */}
      <div className='d-flex flex-column flex-column-fluid'>
        {/*begin::Content*/}
        <div className='justify-content-center d-flex flex-column flex-column-fluid text-center p-10 py-lg-15'>
          {/*begin::Illustration*/}
          <div
            className='d-flex flex-row-auto bgi-no-repeat bgi-position-x-center bgi-size-contain bgi-position-y-bottom min-h-100px min-h-lg-350px'
            style={{
              backgroundImage: `url('${toAbsoluteUrl('/media/illustrations/sketchy-1/17.png')}')`,
            }}
          ></div>
          {/*end::Illustration*/}
          {/*begin::Wrapper*/}
          <div className='pt-lg-10 mb-10'>
            {/*begin::Message*/}
            <h1 className='fw-bold mb-10' style={{ color: '#A3A3C7' }}>
              You do not have permission to access
            </h1>
            {/*end::Message*/}
            {/*begin::Action*/}
            <div className='text-center'>
              <Link to='/' className='btn btn-primary'>
                Return Home
              </Link>
            </div>
            {/*end::Action*/}
          </div>
          {/*end::Wrapper*/}
        </div>
        {/*end::Content*/}
      </div>
      {/*end::Authentication - Error 500*/}
    </div >
  )
}

export { Error500 }
