import { ListViewProvider } from './core/ListViewProvider'
import { QueryRequestProvider } from './core/QueryRequestProvider'
import { QueryResponseProvider } from './core/QueryResponseProvider'
import { Header } from './components/header/Header'
import { Table } from './table/Table'
import { KTCard } from '../../../../_rashmicab/helpers'

const UnsubscribedUsersList = () => {
    return (
        <>
            <KTCard className="card card-xxl-stretch mb-5 mb-xl-8">
                <Header />
                <Table />
            </KTCard>
        </>
    )
}

const UnsubscribedUserList = () => (
    <QueryRequestProvider>
        <QueryResponseProvider>
            <ListViewProvider>
                <UnsubscribedUsersList />
            </ListViewProvider>
        </QueryResponseProvider>
    </QueryRequestProvider>
)

export { UnsubscribedUserList }
