/* eslint-disable react-hooks/exhaustive-deps */
import ReactPaginate from 'react-paginate';
import { useQueryResponsePagination } from '../../core/QueryResponseProvider'
import { useQueryRequest } from '../../core/QueryRequestProvider'
import { pageSizes } from '../../../../../../_rashmicab/helpers'
import { useState, useEffect } from 'react'

const Pagination = () => {
    const pagination = useQueryResponsePagination()
    const { updateState } = useQueryRequest()
    const [currentPage, setCurrentPage] = useState(0);
    const totalPageCount = pagination.pageCount;
    const pagecount = pagination ? pagination.pageSize === "all" ? pagination.total : (Number(pagination.pageSize) * Number(pagination.page)) : 0;

    useEffect(() => {
        setCurrentPage(0);
        updateState({ ...pagination, page: 1 });
    }, [totalPageCount]);

    const handlePageClick = (event: any) => {
        setCurrentPage(event.selected)
        var page = event.selected + 1;
        updateState({ ...pagination, page: page })
    };


    return (
        <div className='row'>
            {/* <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'>
                <div className='d-flex'>
                    <select
                        className='form-select form-select-solid fw-bolder w-auto'
                        data-kt-select2='true'
                        data-placeholder='Select option'
                        data-allow-clear='true'
                        data-kt-agent-table-filter='role'
                        data-hide-search='true'
                        onChange={(e) => handlePageSize(e.target.value)}
                        value={pagination.pageSize}
                    >
                        {pageSizes.map((i) => (
                            <option key={"key" + i} value={i}>{i}</option>
                        ))}
                    </select>
                    <span className=" d-flex align-items-center justify-content-center justify-content-md-start"> &nbsp;
                        {pagination.page === 1 ? 1 : (Number(Number(pagination.page - 1)) * Number(pagination.pageSize)) + 1}
                        - {
                            (pagecount !== null && pagination !== null && typeof pagecount !== "undefined" && typeof pagination !== "undefined") ?
                                (typeof pagination.total !== "undefined" && pagination.total !== null) ? (pagecount > pagination.total ? pagination.total : pagecount) : 0
                                : 0}
                        &nbsp;entries of {pagination.total}</span>
                </div>
            </div> */}
            <div className='col-sm-12 col-md-7 d-flex align-items-center ms-auto mt-2 justify-content-center justify-content-md-end'>
                <div id='kt_table_agents_paginate'>
                    <ReactPaginate
                        forcePage={currentPage}
                        breakLabel="..."
                        previousLabel={<i className="la la-angle-double-left" />}
                        nextLabel={<i className="la la-angle-double-right" />}
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={2}
                        marginPagesDisplayed={2}
                        pageCount={totalPageCount}
                        containerClassName="pagination"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousLinkClassName="page-link"
                        nextLinkClassName="page-link"
                        activeClassName="active"
                        previousClassName="page-item previous "
                        nextClassName="page-item next"
                        renderOnZeroPageCount={null}
                    />
                </div>
            </div>
        </div>
    )
}

export { Pagination }
