const QUERIES = {
  AGENTS_LIST: 'agents',
  INACTIVEAGENTS_LIST: 'inactiveagents',
  DRIVERS_LIST: 'drivers',
  CABS_LIST: 'cabs',
  EDRIVERS_LIST: 'driverse',
  ECABS_LIST: 'cabse',
  WALLETS_LIST: 'wallets',
  SUBSCRIPTIONS_LIST: 'subscriptions',
  STAFFS_LIST: 'staffs',
  CUSTOMERS_LIST: 'customers',
  CABTYPES_LIST: 'cabtypes',
  SUBSCRIPTIONPLANS_LIST: 'subscriptionplans',
  TRIPTYPES_LIST: 'triptypes',
  TRIPS_LIST: 'trips',
  ONLINETRIPS_LIST: 'onlinetrips',
  LOCATIONS_LIST: 'locations',
  TRIPROUTES_LIST: 'triproutes',
  BIDS_LIST: 'bids',
  HELPDESKS_LIST: 'helpdesks',
  SLIDERS_LIST: 'sliders',
  REASONS_LIST: 'reasons',
  BLOCKCUSTOMER_LIST: 'blockcustomer',
  BLOCKAGENT_LIST: 'blockagent',
  COMPLAINS_LIST: 'complains',
  CUSTOMERCOMPLAINS_LIST: 'customercomplains',
  AGENTCOMPLAINS_LIST: 'agentcomplains',
  NOTIFICATIONS_LIST: 'notifications',
  INVOICES_LIST: 'invoices',
  UNSUBSCRIBEDUSERS_LIST: 'unsubscribedusers',
  EXPIREDAGENTS_LIST: 'expiredagent'
}

export { QUERIES }
