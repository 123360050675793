import {FC} from 'react'
import {Link} from 'react-router-dom'
import {Onlinetrip} from '../../core/_models'

type Props = {
  trip: Onlinetrip
  id: any
}

const InfoCell: FC<Props> = ({trip, id}) => (
  <div className='d-flex align-items-center'>
    <div className='d-flex flex-column'>
      <Link className='text-gray-800 text-hover-primary mb-1' to={'/app/onlinetrips/' + id}>
        RC#{id} &nbsp;&nbsp;
        {`${
          typeof trip.triptype !== 'undefined'
            ? trip.triptype.data !== null
              ? trip.triptype.data.attributes.title
                ? trip.triptype.data.attributes.title
                : ''
              : ''
            : ''
        }`}
        {`${typeof trip.onlinetrip_type !== 'undefined' && trip.onlinetrip_type !== null ? trip.onlinetrip_type : ''}`}
        {`${
          typeof trip.cabtype !== 'undefined'
            ? trip.cabtype.data !== null
              ? trip.cabtype.data.attributes.title
                ? ' (' + trip.cabtype.data.attributes.title + ')'
                : ''
              : ''
            : ''
        }`}
      </Link>
      <span>
        {`${
          typeof trip.online_trip_from !== 'undefined'
            ? trip.online_trip_from !== null
              ? trip.online_trip_from
              : ''
            : ''
        }`}
        {`${
          typeof trip.online_trip_to !== 'undefined'
            ? trip.online_trip_to !== null
              ? ' - ' + trip.online_trip_to
              : ''
            : ''
        }`}
      </span>
    </div>
  </div>
)

export {InfoCell}
