import { FC } from 'react'
import { Driver } from '../../core/_models'

type Props = {
    driver: Driver,
}

const LicenseCell: FC<Props> = ({ driver }) => (
    <div className='d-flex align-items-center'>
        <div className='d-flex flex-column'>
            <span>{driver.license === null ? '-' : driver.license}</span>
        </div>
    </div>
)

export { LicenseCell }
