import { useMemo } from 'react'
import { useTable, ColumnInstance, Row } from 'react-table'
import { CustomHeaderColumn } from './columns/CustomHeaderColumn'
import { CustomRow } from './columns/CustomRow'
import { Columns } from './columns/_columns'
import { Driver } from '../core/_models'
import { useQueryResponseData, useQueryResponseLoading } from '../core/QueryResponseProvider'
import { Loading } from '../components/loading'
import { KTCardBody } from '../../../../../_rashmicab/helpers'

const Table = () => {
    const drivers = useQueryResponseData()
    const isLoading = useQueryResponseLoading()
    const data = useMemo(() => drivers, [drivers])
    const columns = useMemo(() => Columns, [])
    const { getTableProps, getTableBodyProps, headers, rows, prepareRow } = useTable({
        columns,
        data,
    })

    return (
        <KTCardBody className='py-4'>
            <div className='table-responsive height-467'>
                <table
                    id='kt_table_drivers'
                    className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
                    {...getTableProps()}
                >
                    <thead className="sticky-top bg-white">
                        <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                            {headers.map((column: ColumnInstance<Driver>) => (
                                <CustomHeaderColumn key={column.id} column={column} />
                            ))}
                        </tr>
                    </thead>
                    <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
                        {rows.length > 0 ? (
                            rows.map((row: Row<Driver>, i) => {
                                prepareRow(row)
                                return <CustomRow row={row} key={`row-${i}-${row.id}`} />
                            })
                        ) : (
                            <tr>
                                <td colSpan={7}>
                                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                                        No matching records found
                                    </div>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            {isLoading && <Loading />}
        </KTCardBody>
    )
}

export { Table }
