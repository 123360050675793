import { FC } from 'react'
import { UnsubscribedUser } from '../../core/_models'

type Props = {
    agent: UnsubscribedUser,
}

const StatusCell: FC<Props> = ({ agent }) => {
    return (
        <span className={agent.status === "default" ? 'cursor-pointer badge badge-light-success me-auto' : agent.status === "inactive" ? 'cursor-pointer badge badge-light-danger me-auto' : agent.status === "approve" ? 'cursor-pointer badge badge-light-primary  me-auto' : 'cursor-pointer badge badge-light-success me-auto'}>{agent.status === null ? '-' :
            agent.status === "default" ? "Active" : agent.status === "approve" ? "Approve" : agent.status === "inactive" ? "inactive" : "-"
        }</span>
    )
}

export { StatusCell }
