import { FC } from 'react'
import { Link } from 'react-router-dom'
import { Trip } from '../../core/_models'

type Props = {
    trip: Trip,
    id: any
}

const InfoCell: FC<Props> = ({ trip, id }) => {
    return (
        <div className='d-flex align-items-center'>
            <div className='d-flex flex-column'>
                <Link className='text-gray-800 text-hover-primary mb-1' to={trip.creator.data.attributes.role.data.attributes.type === "onlinecustomer" ? '/app/onlinetrips/' + id : '/app/trips/' + id} >
                   RC#{id} &nbsp;&nbsp;
                    {`${typeof trip.triptype !== 'undefined' ? trip.triptype.data !== null ?
                        trip.triptype.data.attributes.title ?
                            trip.triptype.data.attributes.title
                            : '' : '' : ''}`}
                </Link>
                <span>
                    {`${typeof trip.route !== 'undefined' ? trip.route.data !== null ?
                        trip.route.data.attributes.from.data !== null ?
                            trip.route.data.attributes.from.data.attributes.city + " (" + trip.route.data.attributes.from.data.attributes.state + ")"
                            : '' : '' : ''}`}
                    {`${typeof trip.route !== 'undefined' ? trip.route.data !== null ?
                        trip.route.data.attributes.to.data !== null ?
                            " - " + trip.route.data.attributes.to.data.attributes.city + " (" + trip.route.data.attributes.to.data.attributes.state + ")"
                            : '' : '' : ''}`}
                </span>
            </div>
        </div>
    )
}
export { InfoCell }
