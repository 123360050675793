import { Route, Routes } from 'react-router-dom'
import {  PageTitle } from '../../../_rashmicab/layout/core'
import { Settings } from './setting';

const Setting = () => {
  return (
    <Routes>
      <Route index element={
        <><PageTitle breadcrumbs={[
            {
              title: 'Setting',
              path: '/setting',
              isSeparator: false,
              isActive: false,
            },
            {
                title: 'Setting',
                path: '/setting',
              isSeparator: true,
              isActive: false,
            },
          ]}>Setting</PageTitle>
          <Settings />
          </>
      } />
    </Routes>
  )
}

export {Setting}
