import axios, {
    AxiosResponse
} from 'axios'
import {
    QueryResponse,
    Complaint
} from './_models'
import {
    ID,
    Response
} from '../../../../../_rashmicab/helpers'


const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v'
const lsValue = JSON.parse(localStorage.getItem(AUTH_LOCAL_STORAGE_KEY) || '{}')


const API_URL = process.env.REACT_APP_API_URL
// const BASE = `${API_URL}/complainmasters?populate[user][fields][0]=id&populate[user][fields][1]=firstname&populate[user][fields][2]=lastname&populate[user][populate][role][fields][3]=id&populate[trip][fields][4]=id&populate[complaindata][fields][5]=id&populate[complaindata][fields][6]=message`
const UPLOAD_IMAGE = `${API_URL}/upload`
const get = (query: string, token: string): Promise<QueryResponse> => {
    const table = query.split('&').map(pair => pair.split("="));
    const result = Object.fromEntries(table);


    var query_data = "";
    query_data = `${query_data}&pageSize=20`;
    query_data = `${query_data}&page=1`;
    query_data = `${query_data}&role_id=4`;
    // query_data = `${query_data}&sort=complaindata.updatedAt:DESC`;

    return axios
        .get(`${API_URL}/complainmasters/customfind?${query_data}`, {
            headers: {
                "Authorization": `Bearer  ${token}`
            }
        })
        .then((d: AxiosResponse<QueryResponse>) => {
            if (result.pagesize === "all") {
                if (typeof d.data !== "undefined") {
                    if (typeof d.data.meta !== "undefined") {
                        if (typeof d.data.meta.pagination !== "undefined") {
                            if (typeof d.data.meta.pagination.pageSize !== "undefined") {
                                d.data.meta.pagination.pageSize = "all";
                            }
                        }
                    }
                }
            }
            return d.data;
        })
}

const getById = (id: ID, token: string): Promise<any> => {
    return axios
        .get(`${API_URL}/complaindatas?populate=*&populate[user][fields][0]=id&populate[user][fields][1]=firstname&populate[user][fields][2]=lastname&populate[user][populate][role][fields][3]=id&populate[user][populate][image][fields][4]=url&populate[attachment][fields][4]=url&filters[complain][id]=${id}`, {
            headers: {
                "Authorization": `Bearer  ${token}`
            }
        })
        .then((response: AxiosResponse<any>) => response)
        .then((response: Response<any>) => response)
}

const createEntry = (message: Complaint): Promise<any | undefined> => {
    let body = { data: message };
    return axios
        .post(`${API_URL}/complaindatas`, body, {
            headers: { "Authorization": `Bearer  ${lsValue.jwt}` }
        })
        .then((response: AxiosResponse<Response<any>>) => response)
        .then((response: Response<any>) => response)
}
const uploadImage = (file: any): Promise<any> => {
    let formData = new FormData();
    Object.keys(file).map((file_d, index) => (
        formData.append("files", file[file_d])
    ));

    return axios.post(`${UPLOAD_IMAGE}`, formData, {
        headers: {
            "Authorization": `Bearer  ${lsValue.jwt}`,
            "Content-Type": "multipart/form-data",
        }
    })
}
const updateEntry = (id: Number, complaint: Complaint): Promise<any | undefined> => {
    let body = { data: complaint };
    return axios
        .put(`${API_URL}/complainmasters/${id}`, body, {
            headers: { "Authorization": `Bearer  ${lsValue.jwt}` }
        })
        .then((response: AxiosResponse<Response<any>>) => response)
        .then((response: Response<any>) => response)
}
export {
    get, getById, createEntry, uploadImage, updateEntry
}