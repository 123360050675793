import { Column } from 'react-table'
import { InfoCell } from './InfoCell'
import { CustomHeader } from './CustomHeader'
import { LicenseCell } from './LicenseCell'
import { Cab } from '../../core/_models'
import { StatusCell } from './StatusCell'

const Columns: ReadonlyArray<Column<Cab>> = [
    {
        Header: (props) => <CustomHeader tableProps={props} title='Name' className='min-w-125px' />,
        id: 'name',
        Cell: ({ ...props }) => <InfoCell cab={props.data[props.row.index].attributes} id={props.data[props.row.index].id} />,
    },
    {
        Header: (props) => <CustomHeader tableProps={props} title='Cab Number' className='min-w-125px' />,
        accessor: 'number',
        Cell: ({ ...props }) => <LicenseCell cab={props.data[props.row.index].attributes} />,
    },
    {
        Header: (props) => <CustomHeader tableProps={props} title='Status' className='min-w-125px' />,
        accessor: 'approve',
        Cell: ({ ...props }) => <StatusCell cab={props.data[props.row.index].attributes} />,
    },
]

export { Columns }
