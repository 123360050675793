import { FC } from 'react'

type Props = {
	complain: any
}

const InfoCell: FC<Props> = ({ complain }) => (
	<div className='d-flex align-items-center'>
		<div className='d-flex flex-column'>
			<div className='text-gray-800 text-hover-primary mb-1' >
				RCCOMPLAIN#{complain.complain_id}
			</div>
			<span className={complain.complian_status === "closed" ? 'cursor-pointer badge badge-light-danger me-auto' : 'cursor-pointer badge badge-light-success me-auto'}>{complain.complian_status ? complain.complian_status : '-'}</span>
		</div>
	</div>
)

export { InfoCell }
